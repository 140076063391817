import {
  type constraintsType,
  type FacetResult,
  type Resource,
  type searchKeyWordArgs,
  type SearchResult,
  type SearchResults,
} from '@blaw/contracts-api-schema'
import usePagination, { PaginationComponent, PaginationMeta } from '@hooks/usePagination'
import ApiClient from '@modules/ApiClient'
import { FacetLabel, FacetListItem, FilterState } from '@modules/ClauseAnalyzer'
import { addToArray, removeFromArray } from '@modules/utils'
import { AxiosResponse } from 'axios'
import {
  Dispatch,
  MutableRefObject,
  PropsWithChildren,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import { StoreContext } from './StoreContext'
import { useDebounce } from '@hooks/useDebounce'
import { useTranslation } from '@hooks/useTranslation'
import { KeyTermsContext } from './KeyTermsContext'
import { MetadataConfig } from '@modules/MetadataConfig'
import { searchInputDelay } from '@modules/defaults'

export const reqFacetNames = ['contract_type', 'owner']

const sortOrder = [
  'filterOwner',
  'docType',
  'filterStatus',
  'filterIndustry',
  'filterGoverningLaw',
  'filterParty',
  'filterLawFirm',
  'filterYear',
]
const filterType = 'Template'
const title = 'Filter Templates'
const path = '/templates'
const templatesPerPage = 20

export interface TemplatesContextState {
  query: string
  setQuery: Dispatch<SetStateAction<string>>
  error?: string
  setError: Dispatch<SetStateAction<string | undefined>>
  warn: string
  Pagination: PaginationComponent
  pageNum: number
  topOfPageRef: MutableRefObject<HTMLDivElement | null>
  filterType: string
  title: string
  path: string
  sortOrder: string[]
  facetLabels: FacetLabel
  cancelFilters: () => void
  filterState: FilterState
  handleFiltering: (facetName: string, facetItem: string, checked: boolean) => void
  loading: boolean
  setLoading: Dispatch<SetStateAction<boolean>>
  numFilters: () => number
  templates: Resource[]
  setTemplates: Dispatch<SetStateAction<Resource[]>>
  items: TemplatesResponse
  fetchTemplates: (query: string, page: number, filters: FilterState) => Promise<TemplatesResult>
  loadPage: (query: string) => void
  loadNextPage: (page: number) => void
  loadFirstPage: (...args: any) => Promise<void>
  search: (query: string) => void
  fetchingItems: MutableRefObject<boolean>
  injectNewTemplateIntoResults: (new_template: Resource) => void
  metadataConfig?: MetadataConfig
}

type TemplatesResult = {
  templates: Resource[]
  meta: PaginationMeta
  items: {
    facets: FacetResult[]
    count: number
  }
  warn?: string
  error?: string
}

type TemplatesResponse = {
  facets: FacetListItem[]
  count: number
}

type Props = PropsWithChildren & {
  keepFiltersOnSearch?: boolean
  hitsPerPage?: number
}

export const TemplatesContext = createContext({} as TemplatesContextState)

function TemplatesContextProvider({
  children,
  keepFiltersOnSearch,
  hitsPerPage = templatesPerPage,
}: Props) {
  const [query, setQuery] = useState('')
  const [error, setError] = useState<string>()
  const [warn, setWarn] = useState('')
  const [prevFilterState, setPrevFilterState] = useState<FilterState>({})
  const [filterState, setFilterState] = useState<FilterState>({})
  const [loading, setLoading] = useState(true)
  const [templates, setTemplates] = useState<Resource[]>([])
  const [, setMeta] = useState<PaginationMeta>()
  const [facetLabels, setFacetLabels] = useState<FacetLabel>({})
  const [facets, setFacets] = useState<FacetListItem[]>([])
  const [count, setCount] = useState<number>(0)
  const { storeSessionInfo } = useContext(StoreContext)
  const { metadataConfig, loadingMetadataConfig } = useContext(KeyTermsContext)
  const apiClient = new ApiClient(storeSessionInfo, setError)
  const { t } = useTranslation()
  const fetchingItems = useRef(false)

  const {
    routes: { searchUrl },
  } = useContext(StoreContext)

  const { loadFirstPage, Pagination, pageNum } = usePagination({
    loadPage,
    loadNextPage,
    hitsPerPage,
    scrollToTop: () => topOfPageRef.current && topOfPageRef.current.scrollIntoView(),
  })

  const debouncedLoad = useDebounce(loadFirstPage, searchInputDelay, [])
  const debouncedSetFilter = useDebounce(setFilterState, searchInputDelay, [])
  const topOfPageRef = useRef<null | HTMLDivElement>(null)

  useEffect(() => {
    if (JSON.stringify(filterState) !== JSON.stringify(prevFilterState)) {
      loadFirstPage(query)
      setPrevFilterState({ ...filterState })
    }
  }, [filterState])

  useEffect(() => {
    keepFiltersOnSearch || debouncedSetFilter({})
    debouncedLoad(query)
  }, [query])

  useEffect(() => {
    if (!loadingMetadataConfig && metadataConfig) {
      setFacetLabels({
        owner: metadataConfig.getLabel('owner'),
        party_name: metadataConfig.getLabel('party_name'),
        contract_type: metadataConfig.getLabel('contract_type'),
        contract_status: metadataConfig.getLabel('contract_status'),
        company_stakeholder: metadataConfig.getLabel('company_stakeholder'),
        client_name: metadataConfig.getLabel('client_name'),
        batch_job_data: metadataConfig.getLabel('batch_job_data'),
      })
    }
  }, [loadingMetadataConfig, metadataConfig])

  async function fetchTemplates(query: string, page: number, filters: FilterState) {
    const result: TemplatesResult = {
      templates: [],
      meta: { totalPages: 0, totalHits: 0 },
      items: { facets: [], count: 0 },
    }
    filters = Object.fromEntries(Object.entries(filters).filter(([_, v]) => v.length))
    const params: searchKeyWordArgs = {
      userQuery: query,
      systemQuery: [],
      facetMaxValuesPerFacet: 1000,
      facetSortOrder: 'COUNT_DESCENDING',
      context: 'template_document',
      sortField: { fieldName: 'last_updated_date', Direction: 'desc' },
      maxDigests: 0,
      facetsResultType: 'BY_NAME',
      offset: page * hitsPerPage,
      maxResults: hitsPerPage,
      facets: Object.entries(filters).map(
        entry => ({ key: entry[0], value: entry[1] } as constraintsType),
      ),
      ranges: [],
      fetchFields: [],
      facetsNames: reqFacetNames,
      constraints: [],
    }

    try {
      const {
        data: { results, facets, totalHits },
      } = (await apiClient.post(searchUrl, params)) as AxiosResponse<SearchResults>

      const templates = results.map(searchResultToResource)
      const meta = { totalPages: Math.floor(totalHits / hitsPerPage), totalHits: totalHits }
      if (templates?.length) {
        setTemplates(templates)
        setMeta(meta)
        const newFacets: FacetListItem[] = facets.map(({ name, values, type }) => ({
          field: name,
          values: values.map(({ label, value, count }) => ({
            label,
            value,
            type,
            count: count ?? 0,
          })),
        }))
        setFacets(newFacets)
        setCount(totalHits)
        result.meta = meta
        result.templates = templates
      } else {
        setCount(0)
        result.warn = t('page.Contracts.No Results Warning')
      }
    } catch (e) {
      console.error(e)
      result.error = (e as Error).message
    }
    return result
  }

  function searchResultToResource(searchResult: SearchResult): Resource {
    const { metadata } = searchResult
    return {
      id: searchResult.documentId,
      name: searchResult.documentName,
      mimeType: searchResult.mimeType,
      resourceType: 'template_document',
      author: searchResult.modifiedBy || metadata.owner?.value,
      links: { self: '', metadata: '', content: '' },
      updated: metadata.last_updated_date?.value,
      created: searchResult.dateCreated,
      metadata: {
        userMetadata: {
          contract_type: metadata.contract_type?.value,
          description: metadata.description?.value,
        },
        systemMetadata: {},
        customMetadata: { digests: searchResult.digests },
      },
    } as Resource
  }

  async function loadPage(query: string) {
    setError('')
    setWarn('')
    setLoading(true)
    fetchingItems.current = true

    const { templates, meta, warn, error } = await fetchTemplates(query, 0, filterState)

    fetchingItems.current = false
    setLoading(false)
    if (warn) setWarn(warn)
    if (error) setError(error)
    if (!templates.length) return {}

    return meta
  }

  function injectNewTemplateIntoResults(newTemplate: Resource): void {
    setTemplates([newTemplate, ...templates.slice(0, -1)])
  }

  function search(query: string) {
    setQuery(query)
    debouncedLoad(query)
  }

  async function loadNextPage(page: number) {
    setError('')
    setWarn('')
    const { templates: newTemplates, warn, error } = await fetchTemplates(query, page, filterState)

    if (warn) setWarn(warn)
    if (error) setError(error)

    setTemplates([...templates, ...newTemplates])
  }

  function handleFiltering(facetName: string, facetItem: string, checked: boolean) {
    setFilterState(prevState => {
      let previousFacetName = prevState[facetName]
      if (!previousFacetName) {
        previousFacetName = []
      }

      const alreadyChecked = filterState[facetName]?.includes(facetItem)
      if (checked && alreadyChecked) return prevState

      return {
        ...prevState,
        [facetName]: checked
          ? addToArray(previousFacetName, facetItem)
          : removeFromArray(previousFacetName, facetItem),
      }
    })
  }

  function cancelFilters() {
    setFilterState({})
  }

  function numFilters() {
    return Object.values(filterState).reduce(
      (prevValue, filterArray) => prevValue + filterArray.length,
      0,
    )
  }

  const value = {
    query,
    setQuery,
    error,
    setError,
    warn,
    Pagination,
    pageNum,
    topOfPageRef,
    filterType,
    title,
    path,
    sortOrder,
    facetLabels,
    cancelFilters,
    filterState,
    handleFiltering,
    loading,
    setLoading,
    numFilters,
    templates,
    setTemplates,
    items: { facets, count },
    fetchTemplates,
    loadPage,
    loadNextPage,
    loadFirstPage,
    search,
    fetchingItems,
    injectNewTemplateIntoResults,
    metadataConfig,
  }

  return <TemplatesContext.Provider value={value}>{children}</TemplatesContext.Provider>
}

export default TemplatesContextProvider
