import { ActionButton, FontSizes, IButtonProps, SharedColors } from '@fluentui/react'
import { CSSProperties } from 'react'

export type SmallButtonProps = IButtonProps & {
  iconName?: string
  variant?: 'primary' | 'default' | 'destructive'
  style?: CSSProperties
}

const variantColors = {
  primary: SharedColors.cyanBlue10,
  default: 'inherit',
  destructive: SharedColors.red20,
}

export default function SmallButton({
  iconName,
  children,
  onClick,
  style,
  disabled,
  variant = 'default',
}: SmallButtonProps) {
  const color = disabled ? SharedColors.gray10 : variantColors[variant]

  return (
    <ActionButton
      iconProps={{
        iconName,
        style: { margin: 0, color },
        styles: { root: { fontSize: FontSizes.small } },
      }}
      disabled={disabled}
      onClick={onClick}
      style={{
        height: 'auto',
        fontSize: FontSizes.small,
        opacity: disabled ? 0.5 : 1,
        ...style,
      }}
    >
      {children}
    </ActionButton>
  )
}
