import { useContext, useEffect, useState } from 'react'
import { MessageBar, ProgressIndicator, SharedColors } from '@fluentui/react'

import TopNav from '@components/TopNav'
import { DefinitionsContext } from '@contexts/DefinitionsContext'
import { AnalysisToolsContext } from '@contexts/AnalysisToolsContext'
import DefinitionIssueCard from './DefinitionIssueCard'
import StyledStack from '@components/StyledStack'
import TextBadge from '@components/TextBadge'
import CollapsibleItem from '@components/CollapsibleItem'
import NotificationBadge from '@components/NotificationBadge'
import BoldText from '@baseComponents/BoldText'
import { TermWithReferences, TextWithReferences } from '@modules/DocumentDefinitions'
import { useContractTaskPaneViewed } from '@modules/analytics'
import UnstyledList from '@baseComponents/UnstyledList'
import AnalysisToolsStaleWarning from '@components/AnalysisToolsStaleWarning'
import LoadingShimmer from '@components/LoadingShimmer'
import { selectedLeftBorderStyle, unselectedLeftBorderStyle } from '@modules/sharedStyles'
import { useTranslation } from '@hooks/useTranslation'
import QuickMessage from '@components/QuickMessage'

interface IssueContent {
  name: string
  active: boolean
  count: number
  key: string
}

const pageTitle = 'Definition Issues'

export default function DefinitionIssues() {
  const {
    loading,
    definitionIssues,
    definitions,
    firstRefs,
    firstRefsContent,
    undefSpans,
    callGetDefinitions,
    definitionsLoaded,
    issueCount,
  } = useContext(DefinitionsContext)
  const { setAnalysisToolTrigger } = useContext(AnalysisToolsContext)
  const [selected, setSelected] = useState('')
  const { t } = useTranslation()

  const formattedIssues = {
    noRef: {
      name: 'Defined But Never Referenced',
      active: false,
      count: definitionIssues.NO_REF.length,
      key: 'NO_REF',
    },
    redefined: {
      name: 'Defined More Than Once',
      active: false,
      count: definitionIssues.REDEFINED.length,
      key: 'REDEFINED',
    },
    refBeforeDefn: {
      name: 'Referenced Before Defined',
      active: false,
      count: definitionIssues.REF_BEFORE_DEFN.length,
      key: 'REF_BEFORE_DEFN',
    },
    refNotCap: {
      name: 'Reference Not Capitalized',
      active: false,
      count: definitionIssues.REF_NOT_CAP.length,
      key: 'REF_NOT_CAP',
    },
    capWordMissingDef: {
      name: 'Undefined Term',
      active: false,
      count: definitionIssues.CAP_WORD_MISSING_DEFN.length,
      key: 'CAP_WORD_MISSING_DEFN',
    },
  }

  useContractTaskPaneViewed({
    pageTitle,
    eventDetails: [Object.keys(formattedIssues).length.toString()],
  })

  useEffect(() => {
    !definitionsLoaded && enable()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div style={{ background: 'white' }}>
      <TopNav title={pageTitle} showAIBadge />
      <MessageBar>{t('page.Definitions.blurb')}</MessageBar>

      {definitionIssues != undefined && (
        <StyledStack>
          <AnalysisToolsStaleWarning />
          <QuickMessage
            msg={t('page.Definitions.No Issues Message')}
            show={!loading && !issueCount}
            type="info"
            style={{ marginBottom: '1em' }}
          />
          {loading && <ProgressIndicator label={t('label.Analyzing Document')} />}
          {renderIssues()}
        </StyledStack>
      )}
    </div>
  )

  function renderIssues() {
    if (loading) return <LoadingShimmer />
    return (
      <UnstyledList>
        {formattedIssues.noRef.count != 0 && (
          <CollapsibleItem
            key={formattedIssues.noRef.key}
            item={formattedIssues.noRef}
            itemHeader={renderIssueHeader}
            itemContent={() => renderNoRef(definitionIssues.NO_REF)}
            listItemStyles={{ opacity: formattedIssues.noRef.count < 0 ? '0.5' : '1' }}
          />
        )}
        {formattedIssues.redefined.count != 0 && (
          <CollapsibleItem
            key={formattedIssues.redefined.key}
            item={formattedIssues.redefined}
            itemHeader={renderIssueHeader}
            itemContent={() => renderRedefined(definitionIssues.REDEFINED)}
            listItemStyles={{ opacity: formattedIssues.redefined.count < 0 ? '0.5' : '1' }}
          />
        )}
        {formattedIssues.refBeforeDefn.count != 0 && (
          <CollapsibleItem
            key={formattedIssues.refBeforeDefn.key}
            item={formattedIssues.refBeforeDefn}
            itemHeader={renderIssueHeader}
            itemContent={() => renderRefBeforeDefn(definitionIssues.REF_BEFORE_DEFN)}
            listItemStyles={{ opacity: formattedIssues.refBeforeDefn.count < 0 ? '0.5' : '1' }}
          />
        )}
        {formattedIssues.refNotCap.count != 0 && (
          <CollapsibleItem
            key={formattedIssues.refNotCap.key}
            item={formattedIssues.refNotCap}
            itemHeader={renderIssueHeader}
            itemContent={() => renderRefNotCap(definitionIssues.REF_NOT_CAP)}
            listItemStyles={{ opacity: formattedIssues.refNotCap.count < 0 ? '0.5' : '1' }}
          />
        )}
        {formattedIssues.capWordMissingDef.count != 0 && (
          <CollapsibleItem
            key={formattedIssues.capWordMissingDef.key}
            item={formattedIssues.capWordMissingDef}
            itemHeader={renderIssueHeader}
            itemContent={() => renderCapWordMissingDefn(definitionIssues.CAP_WORD_MISSING_DEFN)}
            listItemStyles={{
              opacity: formattedIssues.capWordMissingDef.count < 0 ? '0.5' : '1',
            }}
          />
        )}
      </UnstyledList>
    )
  }

  function renderIssueHeader(issue: IssueContent) {
    return (
      <BoldText style={{ position: 'relative' }}>
        {issue.name}&nbsp;
        {issue.count >= 0 && (
          <NotificationBadge style={{ backgroundColor: SharedColors.cyan10 }}>
            {issue.count}
          </NotificationBadge>
        )}
        {issue.count < 0 && <TextBadge style={{ marginLeft: '0.3em' }}> Coming Soon </TextBadge>}
      </BoldText>
    )
  }

  function getTermIndexAndStyle(key: string, term: number, hasRef: boolean) {
    const termIndex = key + definitions[term].term.block_id + definitions[term].term.offset
    const termStyle = termIndex === selected ? selectedLeftBorderStyle : unselectedLeftBorderStyle
    const refIndex = hasRef ? termIndex + 'ref' : ''
    const refStyle =
      hasRef && refIndex === selected ? selectedLeftBorderStyle : unselectedLeftBorderStyle
    return { termIndex, termStyle, refIndex, refStyle }
  }

  function renderNoRef(issues: number[]): JSX.Element[] {
    return issues.map(value => {
      const { termIndex, termStyle } = getTermIndexAndStyle('NO_REF', value, false)
      return (
        <DefinitionIssueCard
          key={termIndex}
          defined_term={definitions[value]}
          issue_type="NO_REF"
          handleSelected={() => setSelected(termIndex)}
          style={termStyle}
        />
      )
    })
  }

  function renderRedefined(issues: number[][]): JSX.Element[] {
    return issues.map((values, i) => (
      <div style={{ marginBottom: '2em' }} key={i}>
        <div style={{ margin: '0.6em 0' }}>
          <BoldText> {definitions[values[0]].term.text}</BoldText>
        </div>
        {values.map(v => {
          const { termIndex, termStyle } = getTermIndexAndStyle('REDEFINED', v, false)

          return (
            <DefinitionIssueCard
              defined_term={definitions[v]}
              issue_type="REDEFINED"
              key={termIndex}
              handleSelected={() => setSelected(termIndex)}
              style={termStyle}
            />
          )
        })}
      </div>
    ))
  }

  function renderRefBeforeDefn(issues: TermWithReferences[]): JSX.Element[] {
    return issues.map((element, i) => {
      const termText = definitions[element.term].term.text
      const { termIndex, termStyle, refIndex, refStyle } = getTermIndexAndStyle(
        'REF_BEFORE_DEFN',
        element.term,
        true,
      )
      const firstRef = firstRefs[i]
      const firstRefContent = firstRefsContent[i]

      return (
        <div style={{ marginBottom: '0.6em' }} key={i}>
          <div style={{ margin: '0.6em 0' }}>
            <BoldText>{termText}</BoldText>
          </div>
          <DefinitionIssueCard
            key={termIndex}
            style={{ ...termStyle, marginTop: '0.5em' }}
            defined_term={definitions[element.term]}
            issue_type="REF_BEFORE_DEFN"
            handleSelected={() => setSelected(termIndex)}
          />
          <DefinitionIssueCard
            key={refIndex}
            style={{ ...refStyle, marginTop: '0.3em', marginBottom: '1em' }}
            defined_term={definitions[element.term]}
            firstRef={firstRef}
            issue_type="REF_BEFORE_DEFN"
            referenceText={firstRefContent}
            handleSelected={() => setSelected(refIndex)}
          />
        </div>
      )
    })
  }

  function renderRefNotCap(issues: TermWithReferences[]): JSX.Element[] {
    return issues.map((element, i) => {
      const termText = definitions[element.term].term.text
      const { termIndex, termStyle, refIndex, refStyle } = getTermIndexAndStyle(
        'REF_NOT_CAP',
        element.term,
        true,
      )
      const refNotCapSpans = element.references.map(function (i) {
        return undefSpans[i]
      })

      return (
        <div style={{ marginBottom: '0.6em' }} key={i}>
          <div style={{ margin: '0.6em 0' }}>
            <BoldText>{termText}</BoldText>
          </div>
          <DefinitionIssueCard
            key={termIndex}
            style={{ ...termStyle, marginTop: '0.5em' }}
            defined_term={definitions[element.term]}
            issue_type="REF_NOT_CAP"
            handleSelected={() => setSelected(termIndex)}
          />
          <DefinitionIssueCard
            key={refIndex}
            referenceText={refNotCapSpans[0].text}
            defined_term={definitions[element.term]}
            location={refNotCapSpans}
            issue_type="REF_NOT_CAP"
            style={refStyle}
            handleSelected={() => setSelected(refIndex)}
          />
        </div>
      )
    })
  }

  function renderCapWordMissingDefn(issues: TextWithReferences[]) {
    return issues.map((element, i) => {
      const refText = element['ref_text']
      const termIndex = 'CAP_WORD_MISSING_DEFN' + i
      const termStyle = termIndex === selected ? selectedLeftBorderStyle : unselectedLeftBorderStyle
      const capWordMissingDefnSpans = element.references.map(i => {
        return undefSpans[i]
      })

      return (
        <div key={i}>
          <DefinitionIssueCard
            key={i}
            referenceText={refText}
            location={capWordMissingDefnSpans}
            issue_type="CAP_WORD_MISSING_DEFN"
            handleSelected={() => setSelected(termIndex)}
            style={termStyle}
          />
        </div>
      )
    })
  }

  function enable() {
    callGetDefinitions()
    setAnalysisToolTrigger('defex', callGetDefinitions)
  }
}
