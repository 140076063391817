import { useState, useContext, useEffect, Context, FC } from 'react'
import {
  Checkbox,
  FocusZone,
  FocusZoneDirection,
  FontSizes,
  ICheckboxStyleProps,
  ICheckboxStyles,
  IconButton,
  IStyleFunctionOrObject,
  Label,
  List,
  SearchBox,
  Stack,
} from '@fluentui/react'

import { MetadataTypes } from '@blaw/contracts-api-schema'
import { ItemAndCount } from '@modules/ClauseAnalyzer'
import ShowMoreButton from '@components/ShowMoreButton'

const stackTokens = { childrenGap: 10 }
const checkboxStyles: IStyleFunctionOrObject<ICheckboxStyleProps, ICheckboxStyles> = {
  label: { fontSize: FontSizes.size12 },
  root: { marginTop: '0.7em' },
}
const MAX_COLLAPSED_FACETS = 5

interface FacetProp {
  facet: ItemAndCount[]
  label: string | JSX.Element
  Footer: FC
  facetName: string
  filters: string[]
  context: Context<any>
  facetsMinimized?: boolean
}

// Render one facetGroup with checkbox for each facet
export default function Facet({
  facet,
  label,
  facetName,
  Footer,
  filters,
  context,
  facetsMinimized,
}: FacetProp) {
  const { handleFiltering, loading, fetchingItems } = useContext(context)
  const [showAllFacets, setShowAllFacets] = useState(false)
  const [facetsCollapsed, setFacetsCollapsed] = useState(facetsMinimized)
  const [facetItems, setFacetItems] = useState<ItemAndCount[]>([])
  const [filter, setFilter] = useState('')
  const numFacets = facet.length

  useEffect(() => {
    if (facetsCollapsed) {
      setShowAllFacets(false)
      setFilter('')
    }
  }, [facetsCollapsed])

  useEffect(() => {
    const maxFacets = showAllFacets ? numFacets : MAX_COLLAPSED_FACETS
    const items = facet
      .filter(
        facet =>
          facet.value.toLowerCase().includes(filter.toLowerCase()) ||
          facet.label?.toLowerCase().includes(filter.toLowerCase()),
      )
      .slice(0, maxFacets)

    setFacetItems(items)
  }, [facet, filter, numFacets, showAllFacets])

  return (
    <Stack tokens={stackTokens} className="facet-category">
      <FocusZone direction={FocusZoneDirection.vertical}>
        {renderHeader()}
        {renderSearchBar()}
        {renderCheckboxes()}
        <ShowMoreButton
          onClick={() => setShowAllFacets(!showAllFacets)}
          moreShown={showAllFacets}
          hide={facetsCollapsed || facetItems.length < MAX_COLLAPSED_FACETS}
          style={{ marginTop: '0.5em' }}
        />
        <Footer />
      </FocusZone>
    </Stack>
  )

  function renderHeader() {
    return (
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Label style={{ width: '100%', padding: 0 }}>{label}</Label>
        <IconButton
          iconProps={{ iconName: facetsCollapsed ? 'chevronDown' : 'chevronUp' }}
          onClick={() => setFacetsCollapsed(!facetsCollapsed)}
          style={{ height: 'auto' }}
        />
      </div>
    )
  }

  function renderSearchBar() {
    if (!showAllFacets) return null

    return (
      <div style={{ marginTop: '0.3em' }}>
        <SearchBox
          placeholder="Search"
          onChange={e => setFilter(e?.target.value.toLowerCase() || '')}
        />
      </div>
    )
  }

  function renderCheckboxes() {
    if (facetsCollapsed) return null

    return (
      <List
        items={facetItems}
        onRenderCellConditional={renderFacetItem}
        onShouldVirtualize={() => showAllFacets}
        style={{ opacity: loading ? 0.5 : 1 }}
      />
    )
  }

  function renderFacetItem(facetItem?: ItemAndCount) {
    if (!facetItem) return null
    const { value, count, label, type } = facetItem

    return (
      <Checkbox
        styles={checkboxStyles}
        disabled={loading || fetchingItems.current}
        checked={filters.includes(value)}
        key={value}
        label={getLabel(label || value, value, type, count)}
        onChange={(_e, checked = false) => {
          if (fetchingItems.current) return

          handleFiltering(facetName, value, checked)
        }}
      />
    )
  }

  function getLabel(label: string, value: string, type: MetadataTypes | undefined, count: number) {
    if (!type) return `${label || value} (${count})`
    if (value === 'undefined') return label
    if (type !== 'DATE') return `${label} (${count})`
    const date = new Date(label)
    return (
      date.toLocaleDateString() +
      ' at ' +
      date.toLocaleTimeString([], { hour: 'numeric', minute: '2-digit' }) +
      ` (${count})`
    )
  }
}
