import { useContext } from 'react'
import { DatePicker } from '@fluentui/react'

import StyledTextField from '@components/StyledTextField'
import useKeyTermForm from '@hooks/useKeyTermForm'
import { parseShort, shortDate } from '@modules/utils'
import { KeyTermFormProps } from '@modules/KeyTermForm'
import CopyFromSelectionBtn from '@components/CopyFromSelectionBtn'
import { KeyTermFormContext } from '@contexts/KeyTermFormContext'

const DateKeyTermForm: React.FC<KeyTermFormProps> = ({ keyTermType }: KeyTermFormProps) => {
  const { copySelectionDisabled } = useContext(KeyTermFormContext)
  const { newKeyTerm, updateKeyTermNotes, updateDateKeyTerm, copyFromSelection } = useKeyTermForm(
    keyTermType,
    'DATE',
  )

  const value =
    newKeyTerm?.type === 'DATE' && typeof newKeyTerm.value === 'string'
      ? parseShort(newKeyTerm.value)
      : undefined

  return (
    <>
      <DatePicker
        isRequired
        label={keyTermType}
        // TODO: Find better solution. !Array.isArray(newKeyTerm?.value) was a quick fix for multiText -> date form
        value={value}
        onSelectDate={updateDateKeyTerm}
        formatDate={shortDate}
        placeholder="yyyy-mm-dd"
      />
      <StyledTextField
        label="Notes"
        value={newKeyTerm?.data?.notes}
        name="notes"
        onChange={updateKeyTermNotes}
        multiline
      />
      <CopyFromSelectionBtn onClick={copyFromSelection} disabled={copySelectionDisabled} />
    </>
  )
}

export default DateKeyTermForm
