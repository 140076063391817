import { useState, createContext, useEffect, Dispatch, SetStateAction } from 'react'
import { removeCookie } from 'typescript-cookie'

import Routes from '@modules/routes'
import { useCheckUserLoginStatus } from '@hooks/useCheckUserLoginStatus'
import {
  getSession,
  storeSession,
  clearSession,
  SessionInfo,
  SESSION_KEY,
} from '@modules/SessionInfoStorage'
import AccessControl from '@modules/AccessControl'
import logout from '@modules/logout'
import useFeatureFlags from '@hooks/useFeatureFlags'

interface Props {
  children?: any
}

interface Store {
  routes: Routes
  loggedIn: boolean
  triggerLogout: () => Promise<void>
  checkingLoginStatus: boolean
  sessionInfo: SessionInfo
  storeSessionInfo: (info: SessionInfo | null) => void
  getSession: () => SessionInfo
  access: AccessControl
  isResourceAccessEnabled: boolean
  isClauseAdviserEnabled: boolean
  isAIBadgeEnabled: boolean
  isPlaybooksEnabled: boolean
  isContractChatEnabled: boolean
  navigateToExtractedObligationEnabled: boolean | undefined
  pageTitle: string
  setPageTitle: Dispatch<SetStateAction<string>>
}

export type StoreSessionInfo = (info: SessionInfo | null) => void

export const StoreContext = createContext({} as Store)
const routes = new Routes()

function StoreContextProvider(props: Props) {
  const [sessionInfo, setSessionInfo] = useState<SessionInfo>(getSession())
  const {
    isAIBadgeEnabled,
    isClauseAdviserEnabled,
    isContractChatEnabled,
    isPlaybooksEnabled,
    isResourceAccessEnabled,
    loadFlagDataAndSetState,
    navigateToExtractedObligationEnabled,
  } = useFeatureFlags({ storeSessionInfo })
  const [pageTitle, setPageTitle] = useState('')
  const [access, setAccess] = useState(new AccessControl(sessionInfo, isResourceAccessEnabled))
  const { loggedIn, setLoggedIn, checkingLoginStatus } = useCheckUserLoginStatus(
    Boolean(sessionInfo.accessTime),
  )

  useEffect(() => {
    const interval = setInterval(() => {
      if (!checkingLoginStatus && loggedIn) setupFeatureFlags()
    }, 60000)

    return () => clearInterval(interval)
  }, [])

  useEffect(() => {
    if (!checkingLoginStatus && loggedIn) setupFeatureFlags()
  }, [checkingLoginStatus, loggedIn])

  async function setupFeatureFlags() {
    const flagData = await loadFlagDataAndSetState()
    setAccess(new AccessControl(sessionInfo, flagData['KMGT:ENABLE_RESOURCE_ACCESS'].value))
  }

  function storeSessionInfo(info: SessionInfo | null) {
    if (!info) {
      window.newrelic.setUserId(null)
      clearSession()
      setSessionInfo({} as SessionInfo)
      setLoggedIn(false)
      removeCookie(SESSION_KEY)
      return
    }

    window.newrelic.setUserId(info.uuid?.toString() ?? null)
    storeSession(info)
    setSessionInfo(info)
    setLoggedIn(Boolean(info[SESSION_KEY]))
  }

  async function triggerLogout() {
    await logout(storeSessionInfo)
  }

  const value: Store = {
    routes,
    loggedIn,
    triggerLogout,
    checkingLoginStatus,
    sessionInfo,
    storeSessionInfo,
    getSession,
    access,
    isResourceAccessEnabled,
    isClauseAdviserEnabled,
    isAIBadgeEnabled,
    isContractChatEnabled,
    isPlaybooksEnabled,
    navigateToExtractedObligationEnabled,
    pageTitle,
    setPageTitle,
  }

  return <StoreContext.Provider value={value}>{props.children}</StoreContext.Provider>
}

export default StoreContextProvider
