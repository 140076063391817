import styled from 'styled-components'
import { Outlet } from 'react-router-dom'
import Footer from '@components/Footer'
import { FC } from 'react'
import { WEBAPP_HOST } from '@src/constants'
import LinkContentFooter from '@components/LinkContentFooter'
import { useTranslation } from 'react-i18next'

export const LayoutContainer = styled.div`
  height: 100vh;
  max-height: inherit;
  display: flex;
  flex-direction: column;
`

export const FlexContainer = styled.div`
  flex: 1;
  height: 100%;
  min-height: 95vh;
  overflow: hidden;
`

export const ContentContainer = styled.div`
  height: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
`

const ContentWrapper = styled.div`
  flex: 1;
`

const PageLayout: FC = () => {
  const { t } = useTranslation()

  return (
    <LayoutContainer>
      <FlexContainer>
        <ContentContainer>
          <ContentWrapper>
            <Outlet />
          </ContentWrapper>
          <LinkContentFooter href={WEBAPP_HOST} href_name={t('label.webapp_name')} />
        </ContentContainer>
      </FlexContainer>
      <Footer />
    </LayoutContainer>
  )
}

export default PageLayout
