import { DefaultButton } from '@fluentui/react'
import { t } from 'i18next'

const navTitles = t('nav')
export type NavTitles = keyof typeof navTitles

export type NavItem = {
  text: NavTitles
  key: string
  href: string
  Button?: typeof DefaultButton
  group: NavGroups
}

export enum NavGroups {
  pre,
  main,
  analysis,
  action,
}

const navItems: NavItem[] = [
  {
    text: 'Get Started',
    key: '/',
    href: '#/',
    group: NavGroups.pre,
  },
  {
    text: 'Contracts',
    key: 'Contracts',
    href: '#/contracts',
    Button: DefaultButton,
    group: NavGroups.main,
  },
  {
    text: 'Clauses',
    key: 'Clauses',
    href: '#/clauses',
    Button: DefaultButton,
    group: NavGroups.main,
  },
  {
    text: 'Templates',
    key: 'Templates',
    href: '#/templates',
    Button: DefaultButton,
    group: NavGroups.main,
  },
  {
    text: 'Similar Clauses',
    key: 'Similar Clauses',
    href: '#/clauses/similar',
    Button: DefaultButton,
    group: NavGroups.analysis,
  },
  {
    text: 'Section Numbering',
    key: 'Section Numbering',
    href: '#/sections/issues',
    Button: DefaultButton,
    group: NavGroups.analysis,
  },
  {
    text: 'Defined Terms',
    key: 'Defined Terms',
    href: '#/definitions/terms',
    Button: DefaultButton,
    group: NavGroups.analysis,
  },
  {
    text: 'Definition Issues',
    key: 'Definition Issues',
    href: '#/definitions/issues',
    Button: DefaultButton,
    group: NavGroups.analysis,
  },
  {
    text: 'Clause Adviser',
    key: 'Clause Adviser',
    href: '#/clauseAdviser',
    Button: DefaultButton,
    group: NavGroups.analysis,
  },
  {
    text: 'Chat with Contract',
    key: 'Contract Chat',
    href: '#/contracts/chat',
    Button: DefaultButton,
    group: NavGroups.analysis,
  },
  {
    text: 'Playbooks',
    key: 'Playbooks',
    href: '#/playbooks/compliance',
    Button: DefaultButton,
    group: NavGroups.analysis,
  },
  {
    text: 'Practical Guidance',
    key: 'Practical Guidance',
    href: '#/guidance',
    Button: DefaultButton,
    group: NavGroups.analysis,
  },
  {
    text: 'Contract Info',
    key: 'Contract Info',
    href: '#/contracts/detect',
    Button: DefaultButton,
    group: NavGroups.action,
  },
  {
    text: 'Document Outline',
    key: 'Document Outline',
    href: '#/outline',
    Button: DefaultButton,
    group: NavGroups.action,
  },
]

export const preNavItems = filterNavItemsByGroup(NavGroups.pre)
export const mainNavItems = filterNavItemsByGroup(NavGroups.main)
export const analysisNavItems = filterNavItemsByGroup(NavGroups.analysis)
export const actionNavItems = filterNavItemsByGroup(NavGroups.action)

export function filterNavItemsByGroup(...groupNums: NavGroups[]) {
  return navItems.filter(({ group }) => groupNums.includes(group))
}

export function excludeNavItemsBytTitle(navItems: NavItem[], ...titles: NavTitles[]) {
  return navItems.filter(({ text }) => !titles.includes(text))
}
