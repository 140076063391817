import { useContext } from 'react'

import StyledTextField from '@components/StyledTextField'
import useKeyTermForm from '@hooks/useKeyTermForm'
import { KeyTermFormProps } from '@modules/KeyTermForm'
import { KeyTermFormContext } from '@contexts/KeyTermFormContext'
import CopyFromSelectionBtn from '@components/CopyFromSelectionBtn'
import { ComboBox, IComboBoxStyles } from '@fluentui/react'
import { useTranslation } from '@hooks/useTranslation'

const ChoiceTextKeyTermForm: React.FC<KeyTermFormProps> = ({ keyTermType }: KeyTermFormProps) => {
  const { copySelectionDisabled } = useContext(KeyTermFormContext)
  const { newKeyTerm, updateChoiceTextKeyTerm, updateKeyTermNotes, copyFromSelection } =
    useKeyTermForm(keyTermType, 'CHOICE_TEXT')
  const comboBoxStyles: Partial<IComboBoxStyles> = {
    optionsContainerWrapper: { maxHeight: '432px' },
    container: { marginBottom: '1em' },
  }
  const { t } = useTranslation()

  return (
    <>
      <ComboBox
        required
        label={keyTermType}
        styles={comboBoxStyles}
        placeholder={t('placeholder.select-one')}
        defaultSelectedKey={newKeyTerm?.value?.toString()}
        options={newKeyTerm?.items || []}
        onChange={updateChoiceTextKeyTerm}
        allowFreeInput
      />
      <CopyFromSelectionBtn onClick={copyFromSelection} disabled={copySelectionDisabled} />
      <StyledTextField
        label="Notes"
        value={newKeyTerm?.data?.notes.toString()}
        name="notes"
        onChange={updateKeyTermNotes}
        styles={{ root: { marginTop: '1em' } }}
        multiline
      />
    </>
  )
}

export default ChoiceTextKeyTermForm
