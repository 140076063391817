import { useContext, useEffect, useState } from 'react'

import TopNav from '@components/TopNav'
import StyledStack from '@components/StyledStack'
import DefinitionCard from './DefinitionCard'
import { DefinitionsContext } from '@contexts/DefinitionsContext'
import { useContractTaskPaneViewed } from '@modules/analytics'
import AnalysisToolsStaleWarning from '@components/AnalysisToolsStaleWarning'
import { useTranslation } from '@hooks/useTranslation'
import DefinitionsActions from '@components/DefinitionsActions'
import useScrollToTop from '@hooks/useScrollToTop'
import { selectedLeftBorderStyle, unselectedLeftBorderStyle } from '@modules/sharedStyles'
import { MessageBar } from '@fluentui/react'

export default function Definitions() {
  const { topOfPageRef, scrollToTop } = useScrollToTop()
  const { selectedDefinitions, selectDefinition, deselectDefinition } =
    useContext(DefinitionsContext)
  const [viewing, setViewing] = useState('')
  const { t } = useTranslation()
  const pageTitle = t('nav.Defined Terms')

  useEffect(() => {
    scrollToTop()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDefinitions])

  useContractTaskPaneViewed({
    pageTitle,
    eventDetails: [selectedDefinitions?.length.toString()],
  })

  const definitions = selectedDefinitions?.map(
    ({ id, definition, term, referencesInDefinition }) => {
      const style = id.toString() === viewing ? selectedLeftBorderStyle : unselectedLeftBorderStyle
      return (
        <div style={{ marginBottom: '0.3em' }} key={id}>
          <DefinitionCard
            definition={definition}
            term={term}
            references={referencesInDefinition}
            onClose={() => {
              deselectDefinition(id)
            }}
            onClick={selectDefinition}
            onView={() => setViewing(id.toString())}
            style={style}
          />
        </div>
      )
    },
  )

  return (
    <div ref={topOfPageRef}>
      <TopNav title={pageTitle} showAIBadge />
      <MessageBar>{t('page.Definitions.blurb')}</MessageBar>

      <StyledStack>
        <AnalysisToolsStaleWarning />
        <DefinitionsActions />
        {definitions}
      </StyledStack>
    </div>
  )
}
