import { CSSProperties } from 'react'

import { LightTheme } from '@src/themes'

export const selectedLeftBorderStyle: CSSProperties = {
  borderLeft: `solid 0.3em ${LightTheme.palette.themePrimary}`,
}

export const unselectedLeftBorderStyle: CSSProperties = {
  borderLeft: '0.1em solid lightgray',
  padding: '0 0.5em 0 0.7em',
}
