import { useContext } from 'react'

import StyledTextField from '@components/StyledTextField'
import useKeyTermForm from '@hooks/useKeyTermForm'
import { removeNonNumberValues } from '@modules/utils'
import { KeyTermFormProps } from '@modules/KeyTermForm'
import { KeyTermFormContext } from '@contexts/KeyTermFormContext'
import CopyFromSelectionBtn from '@components/CopyFromSelectionBtn'

const DurationMonthsKeyTermForm: React.FC<KeyTermFormProps> = ({
  keyTermType,
}: KeyTermFormProps) => {
  const { newKeyTerm, updateKeyTermNotes, updateNumericKeyTerm, copyFromSelection } =
    useKeyTermForm(keyTermType, 'DURATION_MONTHS')
  const { validationError, copySelectionDisabled } = useContext(KeyTermFormContext)

  if (!newKeyTerm) return null

  return (
    <>
      <StyledTextField
        label={keyTermType}
        name="number"
        suffix="months"
        onChange={updateNumericKeyTerm}
        required
        type="number"
        min="0"
        value={removeNonNumberValues(newKeyTerm)}
        errorMessage={validationError}
      />
      <StyledTextField
        label="Notes"
        multiline
        name="notes"
        onChange={updateKeyTermNotes}
        value={newKeyTerm?.data ? newKeyTerm?.data.notes : ''}
      />
      <CopyFromSelectionBtn onClick={copyFromSelection} disabled={copySelectionDisabled} />
    </>
  )
}

export default DurationMonthsKeyTermForm
