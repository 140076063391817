import { useEffect } from 'react'
import { PrimaryButton, Stack } from '@fluentui/react'
import { useNavigate } from 'react-router-dom'

import TopNav from '@components/TopNav'
import QuickMessage from '@components/QuickMessage'
import StyledStack from '@components/StyledStack'
import { useDataInjection } from '@hooks/useDataInjection'
import { useContractTaskPaneViewed } from '@modules/analytics'
import { useTranslation } from '@hooks/useTranslation'

const pageTitle = 'Contract Info'

const ContractDetect: React.FC = () => {
  const navigate = useNavigate()
  const { resourceId } = useDataInjection()
  const { t } = useTranslation()

  useEffect(() => {
    if (resourceId) navigate(`/contracts/${resourceId}`)
  })

  useContractTaskPaneViewed({
    pageTitle,
    eventDetails: [resourceId ?? 'unknown'],
  })

  return (
    <div>
      <TopNav title={pageTitle} />
      <StyledStack>
        <Stack.Item>
          <QuickMessage type="warning" msg={t('label.contract-not-added')} />
        </Stack.Item>
        <Stack.Item>
          <PrimaryButton style={{ width: '100%' }} href="#/contracts/new" autoFocus>
            Save as New Contract
          </PrimaryButton>
        </Stack.Item>
      </StyledStack>
    </div>
  )
}

export default ContractDetect
