import { IMessageBarStyles, MessageBar, MessageBarType } from '@fluentui/react'

type Props = {
  message?: string | null
  type?: MessageBarType
}

const styles: IMessageBarStyles = {
  root: {
    marginBottom: '0.7em',
  },
}

export default function ErrorMessage({ message, type = MessageBarType.error }: Props) {
  if (!message) return null

  return (
    <MessageBar styles={styles} messageBarType={type}>
      {message}
    </MessageBar>
  )
}
