import { useContext } from 'react'
import { DefaultButton, MessageBar, MessageBarType } from '@fluentui/react'

import { AnalysisToolsContext } from '@contexts/AnalysisToolsContext'

type Props = {
  message: string
  showAnalyzeBtn: boolean
}

const AnalysisStaleWarning: React.FC<Props> = ({ message, showAnalyzeBtn = true }: Props) => {
  const { analysisIsStale, setAnalysisIsStale, runEnabledAnalyses } =
    useContext(AnalysisToolsContext)

  function reRunAnalysis() {
    setAnalysisIsStale(false)
    runEnabledAnalyses()
  }

  function renderReAnalyzeButton() {
    if (!showAnalyzeBtn) return
    return (
      <DefaultButton
        style={{ marginTop: '0.5em', width: '100%' }}
        onClick={() => {
          reRunAnalysis()
        }}
      >
        Reanalyze
      </DefaultButton>
    )
  }

  return (
    <div style={{ display: analysisIsStale ? '' : 'none', marginBottom: '0.2em' }}>
      <MessageBar messageBarType={MessageBarType.warning}>{message}</MessageBar>
      {renderReAnalyzeButton()}
    </div>
  )
}

export default AnalysisStaleWarning
