import {
  STAGE,
  LOGIN_URL,
  ADD_IN_HOST,
  ECDS_HOST,
  AUTH_API_HOST,
  CONTRACTS_API_HOST,
  CONTRACTS_API_STAGE,
  SERVICES_API_HOST,
  SERVICES_API_STAGE,
} from '../constants'
import { UserMetadata } from './Contract'
import { AnalysisType } from './DocumentAnalysisAsync'

// Define API URLs
export default class Routes {
  stage: string
  loginUrl: string
  ecdsHost: string
  addInHost: string
  authApiHost: string
  contractsApiHost: string
  contractsApiStage: string
  servicesApiHost: string
  servicesApiStage: string

  constructor() {
    this.stage = STAGE
    this.loginUrl = LOGIN_URL
    this.addInHost = ADD_IN_HOST
    this.ecdsHost = ECDS_HOST
    this.authApiHost = AUTH_API_HOST
    this.contractsApiHost = CONTRACTS_API_HOST
    this.contractsApiStage = CONTRACTS_API_STAGE
    this.servicesApiHost = SERVICES_API_HOST
    this.servicesApiStage = SERVICES_API_STAGE
  }

  get refreshUrl() {
    return `${this.ecdsHost}/auth/v1/tokenRefresh`
  }

  get loggedInUrl() {
    return `${this.ecdsHost}/wserve/api/logged`
  }

  get contractsApiUrl() {
    return `${this.contractsApiHost}/${this.contractsApiStage}`
  }

  get servicesApiUrl() {
    return `${this.servicesApiHost}/${this.servicesApiStage}`
  }

  get authApiUrl() {
    return `${this.authApiHost}/auth`
  }

  get contractsUrl() {
    return `${this.contractsApiUrl}/contracts`
  }

  get searchUrl() {
    return `${this.servicesApiUrl}/search-keyword`
  }

  get createContractUrl() {
    return `${this.servicesApiUrl}/create-contract`
  }

  get resourcesUrl() {
    return `${this.servicesApiUrl}/resources`
  }

  get contractUrl() {
    return `${this.contractsApiUrl}/contract`
  }

  putMetadataKeyUrl(id: string, version: string, key: keyof UserMetadata) {
    const path = `resource/${id}/versions/${version}/metadata/key/${key}`
    return `${this.contractsApiUrl}/${path}`
  }

  get clausesUrl() {
    return `${this.contractsApiUrl}/clauses`
  }

  get createSimilarClausesUrl() {
    return `${this.contractsApiUrl}/similar-clauses-analysis`
  }

  get similarClausesRealmsUrl() {
    return `${this.contractsApiUrl}/similar-clauses-realms`
  }

  getSimilarClausesUrl(id: string) {
    return `${this.contractsApiUrl}/similar-clauses/${id}`
  }

  get redlineUrl() {
    return `${this.contractsApiUrl}/redline`
  }

  get definitionsUrl() {
    return `${this.contractsApiUrl}/defex`
  }

  getDocumentAnalysisUploadUrl(prefix: string) {
    return `${this.contractsApiUrl}/defex_upload/${prefix}`
  }

  getDocumentAnalysisUrl(prefix: string, type: AnalysisType) {
    return `${this.contractsApiUrl}/defex_analysis/${prefix}?analysisType=${type}`
  }

  editMetadataUrl(id: string, version: string) {
    const path = `resource/${id}/versions/${version}/metadata`
    return `${this.contractsApiUrl}/${path}`
  }

  putVersionUrl(id: string) {
    return `${this.contractsApiUrl}/resources/${id}/version`
  }

  contractUploadLink(count = 1) {
    return `${this.contractsApiUrl}/contract/link?count=${count}`
  }

  get autosyncUrl() {
    return `${this.contractsApiUrl}/autosync`
  }

  getAutosyncDetailsUrl(resourceId: string) {
    return `${this.autosyncUrl}/${resourceId}`
  }

  getAutosyncContentUrl(resourceId: string) {
    return `${this.autosyncUrl}/${resourceId}/base64`
  }

  clausePath(documentId: string) {
    return `https://www.bloomberglaw.com/document/${documentId}`
  }

  putClauseAdvisorUrl(requestType: 'rating' | 'generate') {
    return `${this.contractsApiUrl}/clauseAdvisor?requestType=${requestType}`
  }

  get compareVersionsUrl() {
    return `${this.contractsApiUrl}/compare_versions`
  }

  get metadataConfigUrl() {
    return `${this.servicesApiUrl}/configs`
  }

  get userInfoUrl() {
    return `${this.servicesApiUrl}/user-info`
  }

  putUpdateAccessUrl(id: string) {
    return `${this.servicesApiUrl}/update-access/${id}`
  }

  get bulkMetadataUrl() {
    return `${this.contractsApiUrl}/resources/bulk-metadata`
  }

  featureFlagsUrl(featureFlag: string) {
    return `${this.contractsApiUrl}/featureFlags/${featureFlag}`
  }

  get contractChatUrl() {
    return `${this.contractsApiUrl}/contract/chat`
  }

  get pdfConversionUrl() {
    return `${this.contractsApiUrl}/pdfToDocxConversion`
  }

  createContractFromTemplateUrl(templateId: string) {
    return `${this.contractsApiUrl}/template/${templateId}/contract`
  }

  get playbooksComplianceUrl() {
    return `${this.contractsApiUrl}/playbooks/compliance`
  }
}
