import { CSSProperties, useState, MouseEvent } from 'react'
import {
  FontWeights,
  Icon,
  ILabelStyleProps,
  ILabelStyles,
  IStyleFunctionOrObject,
  Label,
  NeutralColors,
} from '@fluentui/react'

import UnstyledButton from '@baseComponents/UnstyledButton'

export interface CollapsibleItemProps<T> {
  item: T
  itemHeader: (item: T) => string | JSX.Element | JSX.Element[]
  itemHeaderButton?: (item: T) => JSX.Element
  itemContent: (item: T) => JSX.Element | JSX.Element[]
  itemFooter?: (item: T) => JSX.Element | JSX.Element[]
  itemCheckbox?: (item: T) => JSX.Element | JSX.Element[]
  toggleItem?: (item: T) => void
  onToggleItem?: (item: T) => void
  onSelectItem?: (item: T) => void
  listItemStyles?: CSSProperties
  buttonStyles?: CSSProperties
  iconStyles?: CSSProperties
  labelStyles?: IStyleFunctionOrObject<ILabelStyleProps, ILabelStyles>
  wrapperStyles?: CSSProperties
  innerWrapperStyles?: CSSProperties
}

type State = {
  children?: any
  paragraph_index?: number
  key: string
  active: boolean
}

const defaultListItemStyles: CSSProperties = {
  borderBottom: `1px solid ${NeutralColors.gray50}`,
}

const defaultButtonStyles: CSSProperties = {
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
  width: '100%',
  position: 'relative',
}

const defaultIconStyles: CSSProperties = {
  fontWeight: FontWeights.bold,
  cursor: 'pointer',
  padding: '0.5em',
}

const defaultLabelStyles: IStyleFunctionOrObject<ILabelStyleProps, ILabelStyles> = {
  root: {
    cursor: 'pointer',
    fontWeight: FontWeights.semibold,
  },
}

const outlineButtonStyles: CSSProperties = {
  display: 'flex',
  width: '100%',
  padding: '0.5em 0',
}

const hoverStyles = `
  .outline-item:hover{
    background-color: #EDEBE9;
  }

  .outline-button:hover {
    background: none
  }
`

export default function CollapsibleItem<T extends State>({
  item,
  itemContent,
  itemHeader = () => <></>,
  itemHeaderButton = () => <></>,
  itemFooter = () => <></>,
  itemCheckbox = () => <></>,
  onToggleItem = () => null,
  onSelectItem = item => item,
  listItemStyles,
  buttonStyles,
  iconStyles,
  labelStyles,
  wrapperStyles,
  innerWrapperStyles,
}: CollapsibleItemProps<T>) {
  const [itemState, setItemState] = useState(item)

  function handleToggleItem(e: MouseEvent<any>) {
    e.stopPropagation()
    e.preventDefault()
    itemState.active = !itemState.active
    onToggleItem(itemState)
    setItemState({ ...itemState })
  }

  function itemWithChildren(item: T) {
    return (
      <div
        aria-label="Outline Section"
        className="outline-item"
        style={{
          ...defaultButtonStyles,
          ...buttonStyles,
          ...wrapperStyles,
        }}
      >
        <div
          style={{
            width: '100%',
            ...defaultListItemStyles,
          }}
        >
          <div style={{ display: 'flex', ...innerWrapperStyles }}>
            <UnstyledButton
              tabIndex={0}
              aria-label="Chevron Button"
              className="outline-button"
              onClick={e => handleToggleItem(e)}
            >
              <Icon
                iconName={itemState.active ? 'ChevronDown' : 'ChevronRight'}
                style={{ ...defaultIconStyles, ...iconStyles }}
              />
            </UnstyledButton>
            <UnstyledButton
              style={{ ...outlineButtonStyles }}
              className="outline-button"
              onClick={() => {
                onSelectItem(item)
              }}
            >
              <Label styles={{ ...defaultLabelStyles, ...labelStyles }}>
                {itemHeader(itemState)}
              </Label>
            </UnstyledButton>
          </div>
        </div>
      </div>
    )
  }

  function itemWithoutChildren(item: T) {
    return (
      <div
        className="outline-item"
        style={{ ...defaultButtonStyles, ...buttonStyles, ...wrapperStyles }}
      >
        <div
          style={{
            width: '100%',
            ...defaultListItemStyles,
          }}
        >
          <div style={{ marginLeft: '2em', width: '100%' }}>
            <div style={innerWrapperStyles}>
              <UnstyledButton
                style={{ ...outlineButtonStyles }}
                className="outline-button"
                onClick={() => {
                  onSelectItem(item)
                }}
              >
                <Label styles={{ ...defaultLabelStyles, ...labelStyles }}>
                  {itemHeader(itemState)}
                </Label>
              </UnstyledButton>
            </div>
          </div>
        </div>
      </div>
    )
  }

  function regularItem() {
    return (
      <UnstyledButton
        style={{ ...defaultButtonStyles, ...buttonStyles, padding: '0.5em 0' }}
        onClick={e => handleToggleItem(e)}
      >
        <Icon
          iconName={itemState.active ? 'ChevronDown' : 'ChevronRight'}
          style={{ ...defaultIconStyles, ...iconStyles }}
        />
        <Label styles={{ ...defaultLabelStyles, ...labelStyles }}>{itemHeader(itemState)}</Label>
      </UnstyledButton>
    )
  }

  function renderItem(item: T) {
    if (!item.children) return regularItem()
    if (item.children.length) return itemWithChildren(item)
    return itemWithoutChildren(item)
  }

  const regularItemBorder = item.children ? {} : defaultListItemStyles

  return (
    <li
      key={item.key}
      className="CollapsibleItem"
      style={{ ...listItemStyles, ...regularItemBorder }}
    >
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        {itemCheckbox(item)}
        {renderItem(item)}
        {itemHeaderButton(itemState)}
      </div>
      <div className="textContent" style={{ overflow: 'hidden' }}>
        {itemState.active && itemContent(itemState)}
      </div>
      {itemFooter(itemState)}
      <style>{hoverStyles}</style>
    </li>
  )
}
