import { Shimmer, ShimmerElementType } from '@fluentui/react'

interface LoadingShimmerProps {
  size?: number
}

// Note: this is designed to look like a Clause
const row1 = [
  { type: ShimmerElementType.circle },
  { type: ShimmerElementType.gap, width: '3%' },
  { type: ShimmerElementType.line, height: 20 },
]
const row2 = [
  { type: ShimmerElementType.gap, width: '11%' },
  { type: ShimmerElementType.line, height: 5 },
]
const row3 = [
  { type: ShimmerElementType.gap, width: '11%' },
  { type: ShimmerElementType.line, height: 5 },
]

export default function LoadingShimmer(props: LoadingShimmerProps) {
  const length = props.size || 10
  const rows: React.ReactNode[] = Array.from({ length }, (i: number) => i)

  return (
    <div>
      {rows.map(() => {
        return (
          <div key={Math.random()} style={{ margin: '0.1em 0 1em 0' }}>
            <Shimmer shimmerElements={row1} />
            <Shimmer shimmerElements={row2} />
            <Shimmer shimmerElements={row3} />
          </div>
        )
      })}
    </div>
  )
}
