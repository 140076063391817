import { LightTheme } from '@src/themes'
import { hash } from '@modules/utils'
import { getDocBodyText } from '@modules/wordDocument'
import { MutableRefObject } from 'react'
import { MetadataDetail } from './ContractDetail'

const definitionTag = 'blaw-definition-reference-control'
export const definitionFontColor = LightTheme.palette.themeDark
export const definitionHighlightColor = LightTheme.palette.themeLighterAlt

const contentControlPropsToLoad = 'items/tag, items/font'
const softReturnLiteral = '\u000b'
const softReturnPlaceholder = '⏎'

export interface Location {
  text: string
  block_id: string
  offset: number
  length: number
}

export interface IndexedLocation extends Location {
  paragraph_index: number
  subdivision_index: number
  occurrence_index: number
}

export interface Reference extends IndexedLocation {
  definitionId: number
}

export interface Definition {
  term: IndexedLocation
  definition: Location
  references: Reference[]
}

export interface Issues {
  NO_REF: number[]
  REDEFINED: number[][]
  REF_BEFORE_DEFN: TermWithReferences[]
  REF_NOT_CAP: TermWithReferences[]
  CAP_WORD_MISSING_DEFN: TextWithReferences[]
}

export interface DefinedTerm {
  id: number
  term: IndexedLocation
  definition: Location
  referencesInDefinition: Reference[]
}

export interface TermWithReferences {
  term: number
  references: number[]
}

export interface TextWithReferences {
  ref_text: string
  references: number[]
}

function groupBy<T, K>(list: T[], keyGetter: (arg0: T) => K): Map<K, T[]> {
  const groups = new Map<K, T[]>()
  for (const obj of list) {
    const key = keyGetter(obj)
    if (!groups.has(key)) groups.set(key, [])
    groups.get(key)?.push(obj)
  }
  return groups
}

function groupReferencesByParagraph(definedTerms: Definition[]) {
  return groupBy(
    definedTerms.flatMap(o => o.references),
    ref => ref.paragraph_index,
  )
}

async function getReferenceControls(context: Word.RequestContext): Promise<Word.ContentControl[]> {
  const { body } = context.document
  const { contentControls } = body
  contentControls.load(contentControlPropsToLoad)
  await context.sync()
  return contentControls.items.filter(c => c.tag?.startsWith(definitionTag))
}

export function clearDefinedTermsInDocument() {
  return Word.run(async context => {
    const referenceControls = await getReferenceControls(context)
    for (const cc of referenceControls) {
      resetStyle(cc)
      cc.delete(true)
    }
  })
}

function resetStyle(cc: Word.ContentControl): void {
  const [, previousColor, previousHighlightColor, previousBold] = cc.tag.split('|')
  cc.font.color = previousColor
  cc.font.highlightColor = previousHighlightColor === 'null' ? '' : previousHighlightColor
  cc.font.bold = previousBold === 'true'
}

function insertContentControl(range: Word.Range | undefined): Word.ContentControl | null {
  if (!range) return null

  const control = range.insertContentControl()
  const { color, highlightColor, bold } = range.font
  control.tag = `${definitionTag}|${color}|${highlightColor}|${bold}`
  control.appearance = 'BoundingBox'
  control.color = definitionFontColor
  control.font.highlightColor = definitionHighlightColor
  control.font.bold = true
  control.font.color = definitionFontColor
  return control
}

async function searchOccurrences(context: Word.RequestContext, definitions: Definition[]) {
  const { paragraphs } = context.document.body
  paragraphs.load('items')

  await context.sync()
  const referencesByParagraph = groupReferencesByParagraph(definitions)

  return Array.from(referencesByParagraph, ([paragraphIndex, references]) => ({
    paragraph: paragraphs.items[paragraphIndex],
    references,
  }))
    .filter(r => r.paragraph)
    .flatMap(({ paragraph, references }) =>
      references.map(({ definitionId, occurrence_index: occurrenceIndex, text }) => ({
        ranges: paragraph?.search(text, { matchCase: true }),
        definitionId,
        occurrenceIndex,
      })),
    )
}

export async function selectTermInDocument({
  text,
  paragraph_index,
  subdivision_index,
  occurrence_index,
}: Partial<IndexedLocation>) {
  if (
    typeof text === 'undefined' ||
    typeof paragraph_index === 'undefined' ||
    typeof subdivision_index === 'undefined' ||
    typeof occurrence_index === 'undefined'
  )
    throw Error('Cannot select term without text or indexes')

  await Word.run(async context => {
    const { paragraphs } = context.document.body
    paragraphs.load('items')
    await context.sync()

    const paragraph = paragraphs.items[paragraph_index]
    let range: Word.Range | null = null
    let contentControlRanges: Word.Range[] = []
    let resetParagraphFonts

    if (subdivision_index > 0) {
      const { termInSubdivision, ccRanges, resetFonts } = await splitSoftReturns(
        paragraph,
        subdivision_index,
        context,
      )
      range = termInSubdivision
      contentControlRanges = ccRanges
      resetParagraphFonts = resetFonts
    }

    const ranges = (range || paragraph).search(text, { matchCase: true })
    ranges.load('items')
    await context.sync()

    ranges.items[occurrence_index].select(Word.SelectionMode.select)
    await context.sync()

    if (resetParagraphFonts) {
      contentControlRanges.map(range => insertContentControl(range))
      await resetParagraphFonts()
      await context.sync()
    }
  })
}

async function splitSoftReturns(
  paragraph: Word.Paragraph,
  subdivisionIndex: number,
  context: Word.RequestContext,
) {
  const contentControls = paragraph.getContentControls()
  contentControls.load(contentControlPropsToLoad)
  await context.sync()

  // Get a handle on the paragraph's content controls so we can re-highlight them
  // after selecting the `termInSubdivision`.
  const ccRanges = contentControls.items.map(cc => {
    const range = cc.getRange()
    // NOTE: The following eslint error is being ignored. load() is not empty.
    // eslint-disable-next-line office-addins/no-empty-load
    range.load(contentControlPropsToLoad)
    // NOTE: The following eslint error is being ignored. The call to sync is after the loop.
    // eslint-disable-next-line office-addins/call-sync-after-load
    return range
  })
  await context.sync()

  // Save the paragraph's fonts before converting soft returns
  const words = paragraph.split([' '], true)
  words.load('items/font')
  await context.sync()
  const fonts = words.items.map(i => i.font.toJSON())

  // Convert the literal to a printable character otherwise `split` won't split
  // the paragraph correctly, seems to ignore non-printable chars like the soft return.
  paragraph.insertText(
    paragraph.text.replaceAll(softReturnLiteral, softReturnPlaceholder),
    Word.InsertLocation.replace,
  )
  await context.sync()

  // Split on soft returns
  const subdivisions = paragraph.split([softReturnPlaceholder], false)
  subdivisions.load('items')
  await context.sync()

  // Revert the soft returns
  paragraph.insertText(
    paragraph.text.replaceAll(softReturnPlaceholder, softReturnLiteral),
    Word.InsertLocation.replace,
  )
  await context.sync()

  // The above soft return conversions cause font info to be lost
  // need to provide the calling code a way to reset fonts after highlighting
  // the termInSubdivision
  async function resetFonts() {
    const words = paragraph.split([' '])
    words.load('items/font')
    await context.sync()

    fonts.forEach((font, i) => {
      const word = words.items[i]
      if (!word) return

      word.font.bold = Boolean(font.bold)
      word.font.doubleStrikeThrough = Boolean(font.doubleStrikeThrough)
      word.font.italic = Boolean(font.italic)
      word.font.strikeThrough = Boolean(font.strikeThrough)
      word.font.subscript = Boolean(font.subscript)
      word.font.superscript = Boolean(font.superscript)
      if (font.color) word.font.color = font.color
      if (font.highlightColor) word.font.highlightColor = font.highlightColor
      if (font.name) word.font.name = font.name
      if (font.size) word.font.size = font.size
      if (font.underline) word.font.underline = font.underline
    })
  }

  const termInSubdivision = subdivisions.items[subdivisionIndex]
  return { termInSubdivision, ccRanges, resetFonts }
}

async function highlightSearch(
  search: {
    ranges: Word.RangeCollection | undefined
    occurrenceIndex: number
    definitionId: number
  }[],
  context: Word.RequestContext,
  termsCleared: MutableRefObject<boolean>,
): Promise<{ contentControl: Word.ContentControl | null; definitionId: number }[]> {
  const filteredSearch = search
    .map(({ ranges, occurrenceIndex, definitionId }) => ({
      range: ranges?.items[occurrenceIndex],
      definitionId,
    }))
    .filter(({ range }) => range)

  filteredSearch.forEach(({ range }) => {
    range?.load('font')
  })

  await context.sync()
  const highlightPromises = filteredSearch.map(async ({ range, definitionId }) => {
    if (termsCleared.current) throw Error('terms cleared')
    return {
      contentControl: insertContentControl(range),
      definitionId,
    }
  })
  return await Promise.all(highlightPromises)
}

export async function highlightDefinedTermsInDocument(
  definitions: Definition[],
  termsCleared: MutableRefObject<boolean>,
  reset: () => Promise<void>,
) {
  const contentControlIdToDefinitionId = new Map<number, number>()
  await Word.run(async context => {
    const search = await searchOccurrences(context, definitions)
    for (const { ranges } of search) {
      ranges?.load('items')
    }
    await context.sync()
    if (termsCleared.current) return
    try {
      const contentControls = await highlightSearch(search, context, termsCleared)
      for (const { contentControl } of contentControls) {
        contentControl?.load('id')
      }
      await context.sync()

      for (const { contentControl, definitionId } of contentControls) {
        if (!contentControl) continue
        contentControlIdToDefinitionId.set(contentControl.id, definitionId)
      }
    } catch (e) {
      if (!(e as Error).message.includes('terms cleared')) throw e
      else await reset()
    }
  })
  return contentControlIdToDefinitionId
}

function groupReferencesByBlockId(definedTerms: Definition[]) {
  return groupBy(
    definedTerms.flatMap(o => o.references),
    ref => ref.block_id,
  )
}

function isContained(child: Location, parent: Location): boolean {
  const startParent = parent.offset
  const endParent = startParent + parent.length
  return child.offset >= startParent && child.offset < endParent
}

export function getReferencesInDefinitionBlocks(definedTerms: Definition[]): DefinedTerm[] {
  const referencesByBlockId = groupReferencesByBlockId(definedTerms)
  return definedTerms.map(({ definition, term }, index) => {
    const { block_id: blockId } = definition
    const referencesInDefinition = (referencesByBlockId.get(blockId) || []).filter(reference =>
      isContained(reference, definition),
    )
    return { id: index, definition, term, referencesInDefinition }
  })
}

export function getFirstRefsInRefNotDefn(definedTerms: Definition[], issues: TermWithReferences[]) {
  return (issues || []).map(issue => definedTerms[issue.term].references[0])
}

export async function getContentHash() {
  const docBodyText = await getDocBodyText()
  const contentHash = await hash(docBodyText)
  console.debug(`Content hash: ${contentHash}`)
  return contentHash
}

export function getFirstRefText(references: Reference[]): Promise<string[]> {
  return Word.run(async context => {
    const { paragraphs } = context.document.body
    paragraphs.load('items')
    await context.sync()

    const content = references.map(ref => {
      const paragraph = paragraphs.items[ref.paragraph_index]
      return paragraph.text
    })

    await context.sync()
    return content
  })
}

export async function selectDetailInDocument(detail: MetadataDetail, idx: number) {
  return await Word.run(async context => {
    if (!detail.offsets || !detail.paragraphData) return false

    let selected = false

    const { paragraphs } = context.document.body
    paragraphs.load('items')
    await context.sync()

    const paragraph = paragraphs.items[detail.paragraphData[idx].paragraph_index]
    let range: Word.Range | null = null
    let contentControlRanges: Word.Range[] = []
    let resetParagraphFonts

    if (detail.paragraphData[idx].subdivision_index > 0) {
      const { termInSubdivision, ccRanges, resetFonts } = await splitSoftReturns(
        paragraph,
        detail.paragraphData[idx].subdivision_index,
        context,
      )
      range = termInSubdivision
      contentControlRanges = ccRanges
      resetParagraphFonts = resetFonts
    }

    const text = paragraph.text.slice(detail.offsets[idx][0][0], detail.offsets[idx][0][1])
    const ranges = (range || paragraph).search(text, { matchCase: true })
    ranges.load('items')
    await context.sync()

    if (detail.references && paragraph.text === detail.references[idx]) {
      ranges.items[0].select(Word.SelectionMode.select)
      await context.sync()
      selected = true
    }

    if (resetParagraphFonts) {
      contentControlRanges.map(range => insertContentControl(range))
      await resetParagraphFonts()
      await context.sync()
    }

    return selected
  })
}
