import { IContextualMenuItem, List, NeutralColors, FontWeights } from '@fluentui/react'
import { useNavigate } from 'react-router-dom'

import { type Resource, Document } from '@blaw/contracts-api-schema'
import { StyledDividerWithoutMargin } from '@baseComponents/StyledDivider'
import LoadingShimmer from '@components/LoadingShimmer'
import QuickMessage from '@components/QuickMessage'
import ActionsMenu from '@components/ActionsMenu'
import { friendlyDateTime } from '@modules/utils'
import useDocumentOpen from '@hooks/useDocumentOpen'
import DocumentOpenStatus from '@components/DocumentOpenStatus'
import ResourceListHeadings from '@components/ResourceListHeadings'
import { useDataInjection } from '@hooks/useDataInjection'
import { LightTheme } from '@src/themes'
import { getFiletypeIconStyle } from '@modules/Contract'
import HighlightedText from '@baseComponents/HighlightedText'
import { useTranslation } from '@hooks/useTranslation'
import LinkButton from '@components/LinkButton'
import BoldField from '@components/BoldField'
import { CSSProperties } from 'react'

const DISPLAYED_RESOURCETYPES = [
  'contract',
  'folder',
  'document',
  'template_document',
  'template_folder',
  'primary_document',
  'library_clause',
  'extracted_clause',
  'project',
  'envelope',
  'envelope_document',
  'my_company_clause',
]

interface Props {
  resources: Resource[]
  loading?: boolean
  label?: string
  error?: string | null
  showSelectedItem?: boolean
  style?: CSSProperties
}

const documentItems = [
  {
    key: 'downloadOpen',
    text: 'Open',
    iconProps: { iconName: 'OpenInNewWindow' },
  },
  {
    key: 'viewVersions',
    text: 'Version History',
    iconProps: { iconName: 'Boards' },
  },
]

const ResourcesList: React.FunctionComponent<Props> = (props: Props) => {
  const { loading, label = 'contract', error, resources, showSelectedItem, style } = props
  const { loadingDocument, openDocumentError, setOpenDocumentError, openDocumentHandler } =
    useDocumentOpen()
  const { documentId } = useDataInjection()
  const navigate = useNavigate()
  const { t } = useTranslation()

  if (loading) return <LoadingShimmer />
  if (error) return <QuickMessage msg={error} type="error" />
  if (!resources.length) return <QuickMessage msg={`0 ${label}s found`} type="warning" />

  return (
    <div>
      <ResourceListHeadings label={label} />
      <List items={resources} onRenderCell={item => renderResource(item)} />
      <DocumentOpenStatus
        loadingMessage="Opening Document..."
        loadingDocument={loadingDocument}
        openDocumentError={openDocumentError}
        setOpenDocumentError={setOpenDocumentError}
        clearErrorDelay={10000}
      />
    </div>
  )

  async function onDocumentItemClick(item: Resource, action: IContextualMenuItem) {
    // This depends on the type of resource, for contracts primary = contract id and secondary
    // document id, for templates both are template id since there's no parent/child
    const primary_id = item.parent ? item.parent : item.id
    const secondary_id = item.id

    switch (action.key) {
      case 'downloadOpen':
        openDocumentHandler(secondary_id, primary_id, item.mimeType, undefined, item.resourceType)
        break
      case 'viewVersions':
        navigate(`/contracts/${primary_id}/documents/${secondary_id}/versions`)
        break
      default:
        throw new Error(`Invalid action "${action.key}"`)
    }
  }

  function renderResource(item?: Resource) {
    if (!item) return null
    if (!DISPLAYED_RESOURCETYPES.includes(item.resourceType)) return null
    const icon = getFiletypeIconStyle(item.mimeType)
    const selectedStyle =
      documentId === item.id ? `${LightTheme.palette.themeDark} solid 4px` : '4px solid white'
    return (
      <>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '1em 0 1em 0',
            backgroundColor: documentId === item.id ? NeutralColors.gray30 : 'initial',
            borderLeft: showSelectedItem ? selectedStyle : '',
            ...style,
          }}
        >
          <div>
            <LinkButton
              iconName={icon.iconName}
              iconColor={icon.iconColor}
              onClick={() => onDocumentItemClick(item, documentItems[0])}
              buttonStyles={{ root: { fontWeight: FontWeights.semibold } }}
            >
              {item.name}
            </LinkButton>
            {item.metadata?.userMetadata?.contract_type && (
              <BoldField
                label={`${t('label.contract-type')}: `}
                value={item.metadata.userMetadata.contract_type}
              />
            )}
            {item.metadata?.userMetadata?.content_type && (
              <BoldField
                label={`${t('label.file-category')}: `}
                value={`${Document.contentType.getLabel(item.metadata.userMetadata.content_type)}`}
              />
            )}
            {item.metadata?.userMetadata?.description && (
              <BoldField
                label={`${t('label.description')}: `}
                value={item.metadata.userMetadata.description}
              />
            )}
            {item.author && (
              <BoldField
                label={`${t('label.ContractMetadata.last-modified')}: `}
                value={`${friendlyDateTime(item.updated)} by ${item.author}`}
              />
            )}
            <HighlightedText
              text={item?.metadata?.customMetadata?.digests?.[0]}
              title={t('label.keyword-match')}
            />
          </div>
          <ActionsMenu
            items={documentItems}
            onItemClick={(action: IContextualMenuItem) => onDocumentItemClick(item, action)}
          />
        </div>
        <StyledDividerWithoutMargin />
      </>
    )
  }
}

export default ResourcesList
