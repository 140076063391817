import { PropsWithChildren, useContext, useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import {
  ActionButton,
  DefaultButton,
  DocumentCard,
  FontWeights,
  NeutralColors,
  PrimaryButton,
  Stack,
} from '@fluentui/react'

import TopNav from '@components/TopNav'
import GeneralInformation from '@components/GeneralInformation'
import ContractStatus from '@components/ContractStatus'
import ResourcesList from '@components/ResourcesList'
import { StyledDivider } from '@baseComponents/StyledDivider'
import StyledStack from '@components/StyledStack'
import { ContractContext } from '@contexts/ContractContext'
import ContractTitle from '@baseComponents/ContractTitle'
import NotificationBadge from '@components/NotificationBadge'
import { itemClicked, useContractTaskPaneViewed } from '@modules/analytics'
import { StoreContext } from '@contexts/StoreContext'
import { KeyTermsContext } from '@contexts/KeyTermsContext'
import ErrorMessage from '@components/ErrorMessage'
import { useDataInjection } from '@hooks/useDataInjection'
import ContractAccess from '@components/ContractAccess'
import { getSession } from '@modules/SessionInfoStorage'
import { useTranslation } from '@hooks/useTranslation'
import useScrollToTop from '@hooks/useScrollToTop'
import AddFileForm from '@components/AddFileForm'

type ContractViewParams = { id: string }

const ContractView: React.FC = () => {
  const { t } = useTranslation()
  const contractId = useParams<ContractViewParams>().id
  if (!contractId) throw Error('Missing id param')

  const navigate = useNavigate()
  const location = useLocation()
  const {
    error,
    loading,
    updating,
    contract,
    children,
    title,
    updateAccess,
    updateStatus,
    statusFormHidden,
    setStatusFormHidden,
    accessFormHidden,
    setAccessFormHidden,
    updateError,
    setUpdateError,
    errorCode,
  } = useContext(ContractContext)
  const { metadataConfig, loadingMetadataConfig } = useContext(KeyTermsContext)
  const { access, isResourceAccessEnabled, setPageTitle } = useContext(StoreContext)
  const [addFileHidden, setAddFileHidden] = useState(true)
  const numKeyTerms = (metadataConfig && contract?.numExistingKeyTerms(metadataConfig.value)) || 0
  const prevPath = location.state?.previousHash
  const { resourceId } = useDataInjection()
  const userInfo = getSession()
  const { topOfPageRef, scrollToTop } = useScrollToTop({ behavior: undefined })
  scrollToTop()

  const pageTitle = resourceId === contractId ? 'Contract Info' : 'Contract Record'
  useEffect(() => {
    setPageTitle(pageTitle)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const keyTermsHelpText = numKeyTerms
    ? t('page.ContractView.List Key Terms')
    : t('page.ContractView.No Key Terms')

  const currentUserOwns = () => {
    if (!contract) return false
    const deleteGroups = contract.accessControls.deleteList
    const ownerUuids = deleteGroups
      .filter(group => group.startsWith('gu'))
      .map(userGroup => userGroup.slice(2))
    return userInfo.uuid && ownerUuids.includes(userInfo.uuid)
  }

  const showAccessLabel = isResourceAccessEnabled && (access.isAdmin() || currentUserOwns())

  useContractTaskPaneViewed({
    pageTitle,
    eventDetails: [contractId],
  })

  function trackNavigateToKeyTermsPage(buttonLabel: string) {
    itemClicked({
      pageTitle,
      itemClicked: buttonLabel,
      itemLocation: 'middle',
      itemType: 'Form Submit',
      isLoggedIn: true,
    })
  }

  if (!loadingMetadataConfig && metadataConfig?.error) {
    return (
      <div>
        <TopNav title={pageTitle} prevPath={prevPath} />
        <ErrorMessage message={metadataConfig.error} />
      </div>
    )
  }

  if (errorCode == 404) {
    return (
      <Layout>
        <ErrorMessage message="This contract may have been deleted and no longer exists." />
        <PrimaryButton onClick={() => navigate('/contracts')}>
          View {t('label.all-contracts')}
        </PrimaryButton>
      </Layout>
    )
  }

  return (
    <Layout>
      <ContractTitle title={title} />
      <h3
        style={{
          marginBottom: '0.2em',
          fontWeight: 600,
          position: 'relative',
          width: 'fit-content',
        }}
      >
        Contract Attributes
      </h3>
      <Stack.Item>
        {showAccessLabel && (
          <ContractAccess
            contract={contract}
            updating={updating}
            closed={accessFormHidden}
            toggleHidden={() => setAccessFormHidden(!accessFormHidden)}
            onAccessChange={updateAccess}
            error={updateError}
            setError={setUpdateError}
            loading={loading || loadingMetadataConfig}
          />
        )}
      </Stack.Item>
      <Stack.Item>
        <DocumentCard
          style={{
            maxWidth: 'none',
            cursor: 'auto',
            border: `1px solid ${NeutralColors.gray60}`,
            userSelect: 'auto',
            display: loading ? 'none' : 'block',
          }}
        >
          <ContractStatus
            contract={contract}
            updating={updating}
            closed={statusFormHidden}
            toggleHidden={() => setStatusFormHidden(!statusFormHidden)}
            onStatusChange={updateStatus}
            error={updateError}
            setError={setUpdateError}
            loading={loading || loadingMetadataConfig}
          />
        </DocumentCard>
      </Stack.Item>
      <Stack.Item
        style={loading || loadingMetadataConfig ? { display: 'none' } : { display: 'initial' }}
      >
        <GeneralInformation
          showEditBtn={access.canEditContractDetails(contract?.accessControls || {})}
          error={error}
        />
        <h3
          style={{
            marginBottom: '0.2em',
            fontWeight: 600,
            position: 'relative',
            width: 'fit-content',
          }}
        >
          Key Terms
          <NotificationBadge
            style={{ display: numKeyTerms ? 'inline-block' : 'none', marginLeft: '0.2em' }}
          >
            {numKeyTerms}
          </NotificationBadge>
        </h3>
        <p style={{ marginTop: '0.2em' }}>{keyTermsHelpText}</p>
      </Stack.Item>

      <KeyTermsButton />
      <StyledDivider />

      <Stack.Item>
        <Stack>
          <Stack.Item
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              minHeight: '40px',
            }}
          >
            <h3 style={{ fontWeight: FontWeights.semibold, margin: 0 }}>Contract Files</h3>
            <ActionButton
              data-testid="add-file-button"
              iconProps={{ iconName: 'Add' }}
              style={{
                display: access.canEditContractDocuments(contract?.accessControls || {})
                  ? 'block'
                  : 'none',
              }}
              onClick={() => setAddFileHidden(false)}
              disabled={loading || loadingMetadataConfig}
            >
              Add
            </ActionButton>
          </Stack.Item>
        </Stack>
      </Stack.Item>

      <Stack.Item>
        <ResourcesList
          label={t('label.contract-child-resources-list')}
          resources={children}
          loading={loading || loadingMetadataConfig}
          error={error}
          showSelectedItem={true}
          style={{
            paddingLeft: '0.25em',
          }}
        />
      </Stack.Item>

      <AddFileForm
        contractId={contractId}
        hidden={addFileHidden}
        toggleHidden={() => setAddFileHidden(true)}
      ></AddFileForm>
    </Layout>
  )

  function Layout({ children }: PropsWithChildren) {
    return (
      <div ref={topOfPageRef}>
        <TopNav title={pageTitle} prevPath={prevPath} showAIBadge />
        <StyledStack>{children}</StyledStack>
      </div>
    )
  }

  function KeyTermsButton() {
    if (numKeyTerms) {
      return (
        <DefaultButton
          disabled={loading || loadingMetadataConfig}
          style={{ marginBottom: '1.5em' }}
          onClick={() => {
            navigate(`/contracts/${contractId}/keyTerms`)
            trackNavigateToKeyTermsPage('View Key Terms')
          }}
        >
          View Key Terms
        </DefaultButton>
      )
    }

    return (
      <DefaultButton
        disabled={loading || loadingMetadataConfig}
        style={{
          display: access.canEditContractTerms(contract?.accessControls || {})
            ? 'inline-block'
            : 'none',
          marginBottom: '1.5em',
        }}
        onClick={() => {
          navigate(`/contracts/${contractId}/keyTerms`)
          trackNavigateToKeyTermsPage('Add Key Terms')
        }}
      >
        Add Key Terms
      </DefaultButton>
    )
  }
}

export default ContractView
