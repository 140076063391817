import { useState } from 'react'
import { ActionButton, CommandBar, MessageBar, MessageBarType } from '@fluentui/react'
import { NeutralColors } from '@fluentui/theme'
import useClipboard from 'react-use-clipboard'

import Clause from '@components/Clause'
import ShowMore from '@components/ShowMore'
import { navigateToExternalSite } from '@modules/utils'
import Routes from '@modules/routes'
import BoldField from '@components/BoldField'
import ClauseMessage from './ClauseMessage'
import AccessControl from '@modules/AccessControl'
import { CompanyClausesTabIndex, MyClausesTabIndex } from '@pages/ClauseLibrary/ClauseLibrary'
import { useTranslation } from '@hooks/useTranslation'
import { TabIndex } from '@contexts/ClausesContext'
import LinkButton from '@components/LinkButton'

const routes = new Routes()

interface Props {
  item: Clause
  addable: boolean
  editable: boolean
  activeTab: string
  access: AccessControl
  toggleAddNewClauseHidden: (tab?: TabIndex) => void
  setSelectedClause: React.Dispatch<React.SetStateAction<Clause | null>>
}

export default function ClauseContent(props: Props) {
  const { addable, item, activeTab, access, toggleAddNewClauseHidden, setSelectedClause } = props
  const [actionMessage, setActionMessage] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [copied, setCopied] = useClipboard(item.clauseText)
  const { t } = useTranslation()

  return (
    <div>
      <div
        style={{ display: 'flex', flexDirection: 'column', gap: '0.6em', marginBottom: '0.6em' }}
      >
        {item.documentName != 'Untitled' && (
          <BoldField label={'Contract Name: '} value={item.documentName} />
        )}
        <BoldField label={'Contract Type: '} value={item.contractType} />
        <BoldField label={'Clause Title: '} value={item.title} />
        <BoldField label={'Counterparty: '} value={item.counterparty} />
        {activeTab !== '0' && <BoldField label={'Risk Level: '} value={item.riskLevel} />}
        {addable && (
          <LinkButton
            onClick={() =>
              navigateToExternalSite(`${routes.authApiHost}/document/${item.parentId}`)
            }
            iconName="OpenInNewTab"
            buttonStyles={{ label: { paddingLeft: '0.1em' } }}
          >
            {t('button.View Full Document')}
          </LinkButton>
        )}
      </div>
      <div
        style={{
          background: NeutralColors.gray20,
          marginTop: 0,
          padding: '0.5em 0.1em 0 0.5em',
        }}
      >
        <ShowMore content={item.clauseText} maxLength={142} />
        {renderButtons()}
      </div>

      <ClauseMessage actionMessage={actionMessage} />
      <ClauseMessage errorMessage={errorMessage} msgType="error" />
    </div>
  )

  function renderButtons() {
    return (
      <div style={{ minHeight: '40px' }}>
        <CommandBar
          styles={{
            root: {
              background: NeutralColors.gray20,
              maxHeight: '40px',
            },
            primarySet: {
              justifyContent: 'right',
            },
          }}
          buttonAs={ActionButton}
          overflowButtonProps={{
            style: { background: NeutralColors.gray20 },
            menuProps: { items: [], gapSpace: -8 },
          }}
          items={[
            {
              key: 'copy',
              name: 'Copy',
              iconProps: { iconName: 'Copy' },
              onClick: setCopied,
            },
            {
              key: CompanyClausesTabIndex,
              name: 'Add to Company Favorites',
              iconProps: { iconName: 'Add' },
              onClick: () => addToClauses(item, CompanyClausesTabIndex),
            },
            {
              key: MyClausesTabIndex,
              name: 'Add to My Favorites',
              iconProps: { iconName: 'Add' },
              onClick: () => addToClauses(item, MyClausesTabIndex),
            },
          ].filter(item => canViewItem(item.key))}
        />
        {copied && (
          <MessageBar messageBarType={MessageBarType.success}>{t('label.copy-message')}</MessageBar>
        )}
      </div>
    )
  }

  function canViewItem(key: string) {
    if (key === activeTab) return false
    if (key === CompanyClausesTabIndex) return access.canEditCompanyClauses()
    return true
  }

  function addToClauses(clause: Clause, tab: TabIndex) {
    setActionMessage('')
    setErrorMessage('')
    setSelectedClause(clause)
    toggleAddNewClauseHidden(tab)
  }
}
