import {
  createContext,
  useEffect,
  useState,
  useContext,
  useRef,
  MutableRefObject,
  FC,
  Dispatch,
  SetStateAction,
} from 'react'

import { type constraintsType } from '@blaw/contracts-api-schema'
import { addToArray, removeFromArray } from '@modules/utils'
import { FacetLabel, FacetListItem, FilterState } from '@modules/ClauseAnalyzer'
import { KeyTermsContext } from './KeyTermsContext'
import { StoreContext } from './StoreContext'
import { useDebounce } from '@hooks/useDebounce'
import ApiClient from '@modules/ApiClient'
import Clause from '@components/Clause'
import usePagination, { PaginationComponentProps, PaginationMeta } from '@hooks/usePagination'
import { searchInputDelay } from '@modules/defaults'
import {
  ClausesPerPage,
  ClausesResult,
  emptyClauseSearchResults,
  searchClauses,
} from '@modules/ClauseResource'

const sortOrder = [
  'owner',
  'party_name',
  'contract_type',
  'first_draft_origin',
  'company_stakeholder',
  'client_name',
  'contract_category',
  'transaction',
  'contract_id',
  'custom_field_1',
  'custom_field_2',
  'custom_field_3',
]

const extractedClauseFacets = [...sortOrder]
const myFavoritesFacets = ['contract_type', 'clause_type', 'risk_level']
const companyFavoritesFacets = ['contract_type', 'clause_type', 'risk_level']

const filterType = 'Clause'
const filterPageTitle = 'Filter Clauses'
const path = '/clauses'

export const AllClausesLabel = 'Extracted Clauses'
export const CompanyClausesLabel = 'Company Favorites'
export const MyClausesLabel = 'My Favorites'

const tabToSearchParamsMap = {
  '0': {
    context: 'all_clauses',
    facets: extractedClauseFacets,
    constraints: [
      { key: 'contract_status', value: ['final', 'terminated', 'FINAL', 'TERMINATED'] },
    ],
  },
  '1': {
    context: 'my_company_clauses',
    facets: companyFavoritesFacets,
    constraints: [] as constraintsType[],
  },
  '2': { context: 'my_clauses', facets: myFavoritesFacets, constraints: [] as any },
}

interface PropsWithChildren {
  children?: JSX.Element | JSX.Element[]
}

export type TabIndex = keyof typeof tabToSearchParamsMap

type ClausesResponse = {
  facets: FacetListItem[]
  count: number
}

interface ClausesContextState {
  activeTab: TabIndex
  AllClausesLabel: string
  cancelFilters: () => void
  clauses: Clause[]
  CompanyClausesLabel: string
  debouncedLoad: (...args: any[]) => void
  debouncedSetFilter: (...args: any[]) => void
  error: string
  facetLabels: FacetLabel
  fetchingItems: MutableRefObject<boolean>
  filterType: string
  handleFiltering: (facetName: string, facetItem: string, checked: boolean) => void
  items: ClausesResponse
  loadFirstPage: (...args: any) => Promise<void>
  loading: boolean
  pageLoading: boolean
  MyClausesLabel: string
  numFilters: () => number
  pageNum: number
  Pagination: FC<PaginationComponentProps>
  path: string
  query: string
  setActiveTab: Dispatch<SetStateAction<TabIndex>>
  setQuery: Dispatch<SetStateAction<string>>
  sortOrder: string[]
  title: string
  topOfPageRef: MutableRefObject<HTMLDivElement | null>
  filterState: FilterState
  fetchClauses: (
    query: string,
    page: number | undefined,
    tab: TabIndex,
    filters: FilterState,
  ) => Promise<ClausesResult>
}

export const ClausesContext = createContext({} as ClausesContextState)

export default function ClausesContextProvider(props: PropsWithChildren) {
  const [activeTab, setActiveTab] = useState<TabIndex>('0')
  const [clauses, setClauses] = useState([] as Clause[])
  const [count, setCount] = useState<number>(0)
  const [error, setError] = useState<string>('')
  const [facetLabels, setFacetLabels] = useState<FacetLabel>({})
  const [facets, setFacets] = useState<FacetListItem[]>([])
  const [filterState, setFilterState] = useState<FilterState>({})
  const [loading, setLoading] = useState<boolean>(true)
  const [prevFilterState, setPrevFilterState] = useState<FilterState>({})
  const [query, setQuery] = useState('')

  const { storeSessionInfo } = useContext(StoreContext)
  const { metadataConfig, loadingMetadataConfig } = useContext(KeyTermsContext)

  const {
    loadFirstPage,
    loading: pageLoading,
    Pagination,
    pageNum,
  } = usePagination({
    loadPage,
    loadNextPage,
    hitsPerPage: ClausesPerPage,
    scrollToTop: () => topOfPageRef.current && topOfPageRef.current.scrollIntoView(),
  })
  const debouncedLoad = useDebounce(loadFirstPage, searchInputDelay, [])
  const debouncedSetFilter = useDebounce(setFilterState, searchInputDelay, [])
  const topOfPageRef = useRef<null | HTMLDivElement>(null)
  const fetchingItems = useRef(false)
  const apiClient = new ApiClient(storeSessionInfo, setError)

  useEffect(() => {
    if (JSON.stringify(filterState) !== JSON.stringify(prevFilterState)) {
      loadFirstPage(query, activeTab)
      setPrevFilterState({ ...filterState })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterState])

  useEffect(() => {
    setQuery('')
    cancelFilters()
    debouncedLoad('', activeTab)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab])

  useEffect(() => {
    if (loadingMetadataConfig || !metadataConfig) return

    setFacetLabels({
      owner: metadataConfig.getLabel('owner'),
      party_name: metadataConfig.getLabel('party_name'),
      contract_type: metadataConfig.getLabel('contract_type'),
      first_draft_origin: metadataConfig.getLabel('first_draft_origin'),
      company_stakeholder: metadataConfig.getLabel('company_stakeholder'),
      client_name: metadataConfig.getLabel('client_name'),
      contract_category: metadataConfig.getLabel('contract_category'),
      transaction: metadataConfig.getLabel('transaction'),
      contract_id: metadataConfig.getLabel('contract_id'),
      custom_field_1: metadataConfig.getLabel('custom_field_1'),
      custom_field_2: metadataConfig.getLabel('custom_field_2'),
      custom_field_3: metadataConfig.getLabel('custom_field_3'),
      clause_type: metadataConfig.getLabel('clause_type'),
      risk_level: metadataConfig.getLabel('risk_level'),
    })
  }, [loadingMetadataConfig, metadataConfig])

  async function fetchClauses(query: string, page = 0, tab: TabIndex, filters: FilterState) {
    let result = emptyClauseSearchResults()

    try {
      const { context, facets, constraints } = tabToSearchParamsMap[tab]
      result = await searchClauses(page, query, context, facets, filters, constraints, apiClient)

      setFacets(result.facets)
      setCount(result.meta.totalHits ?? 0)
    } catch (e) {
      console.error(e)
      result.error = (e as Error).message
    }

    return result
  }

  async function loadPage(query: string, tab: TabIndex) {
    setError('')
    setLoading(true)
    fetchingItems.current = true

    const { clauses, error, meta } = await fetchClauses(query, 0, tab, filterState)

    fetchingItems.current = false
    setLoading(false)

    if (error) {
      setError(error)
      return {} as PaginationMeta
    }

    setClauses(clauses)
    return meta
  }

  async function loadNextPage(page: number) {
    setError('')
    const { clauses: newClauses, error } = await fetchClauses(query, page, activeTab, filterState)

    if (error) return setError(error)
    setClauses([...clauses, ...newClauses])
  }

  function handleFiltering(facetName: string, facetItem: string, checked: boolean) {
    setFilterState(prevState => {
      let previousFacetName = prevState[facetName]
      if (!previousFacetName) {
        previousFacetName = []
      }
      return {
        ...prevState,
        [facetName]: checked
          ? addToArray(previousFacetName, facetItem)
          : removeFromArray(previousFacetName, facetItem),
      }
    })
  }

  function cancelFilters() {
    setFilterState({})
  }

  function numFilters() {
    return Object.values(filterState).reduce(
      (prevValue, filterArray) => prevValue + filterArray.length,
      0,
    )
  }

  const value = {
    activeTab,
    AllClausesLabel,
    cancelFilters,
    clauses,
    CompanyClausesLabel,
    debouncedLoad,
    debouncedSetFilter,
    error,
    facetLabels,
    fetchingItems,
    filterState,
    filterType,
    handleFiltering,
    items: { facets, count },
    loadFirstPage,
    loading,
    pageLoading,
    MyClausesLabel,
    numFilters,
    pageNum,
    Pagination,
    path,
    query,
    setActiveTab,
    setQuery,
    sortOrder,
    title: filterPageTitle,
    topOfPageRef,
    fetchClauses,
  }

  return <ClausesContext.Provider value={value}>{props.children}</ClausesContext.Provider>
}
