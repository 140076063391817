import AnalysisStaleWarning from '@components/AnalysisStaleWarning'

const AnalysisToolsStaleWarning = () => {
  return (
    <AnalysisStaleWarning
      message="Edits were made to this document after it was analyzed. Click reanalyze to see updated
            issues and terms."
      showAnalyzeBtn={true}
    />
  )
}

export default AnalysisToolsStaleWarning
