import { registerIcons } from '@fluentui/react'

import AIIndicator from '../../../assets/AI-Indicator-idle.svg'
import AIGenerated from '../../../assets/AI-Generated.svg'

registerIcons({
  icons: {
    AIIndicator: <img src={AIIndicator} alt="Bloomberg AI" />,
    AIGenerated: <img src={AIGenerated} alt="AI Generated" />,
  },
})

// The following images are needed in the manifest.xml file
// Asset imports here will be included in the build
import '../../../assets/icon-16.png'
import '../../../assets/icon-32.png'
import '../../../assets/icon-64.png'
import '../../../assets/icon-80.png'

import '../../../assets/new-folder.svg'
import '../../../assets/new-folder-16.png'
import '../../../assets/new-folder-32.png'
import '../../../assets/new-folder-80.png'
import '../../../assets/save.svg'
import '../../../assets/save-16.png'
import '../../../assets/save-32.png'
import '../../../assets/save-80.png'
import '../../../assets/save-plus.svg'
import '../../../assets/save-plus-16.png'
import '../../../assets/save-plus-32.png'
import '../../../assets/save-plus-80.png'
import '../../../assets/show-panel.svg'
import '../../../assets/show-panel-16.png'
import '../../../assets/show-panel-32.png'
import '../../../assets/show-panel-80.png'
import '../../../assets/hide-panel.svg'
import '../../../assets/hide-panel-16.png'
import '../../../assets/hide-panel-32.png'
import '../../../assets/hide-panel-80.png'
import '../../../assets/save-floppy.svg'
import '../../../assets/save-floppy-16.png'
import '../../../assets/save-floppy-32.png'
import '../../../assets/save-floppy-80.png'
import '../../../assets/BulletedTreeList.svg'
import '../../../assets/BulletedTreeList-16.png'
import '../../../assets/BulletedTreeList-32.png'
import '../../../assets/BulletedTreeList-80.png'
import '../../../assets/NumberedList.svg'
import '../../../assets/NumberedList-16.png'
import '../../../assets/NumberedList-32.png'
import '../../../assets/NumberedList-80.png'
import '../../../assets/TextRecognition.svg'
import '../../../assets/TextRecognition-16.png'
import '../../../assets/TextRecognition-32.png'
import '../../../assets/TextRecognition-80.png'
import '../../../assets/ReportWarning.svg'
import '../../../assets/ReportWarning-16.png'
import '../../../assets/ReportWarning-32.png'
import '../../../assets/ReportWarning-80.png'
import '../../../assets/Info-16.png'
import '../../../assets/Info-32.png'
import '../../../assets/Info-80.png'
import '../../../assets/entitlement-policy.svg'
import '../../../assets/entitlement-policy-16.png'
import '../../../assets/entitlement-policy-32.png'
import '../../../assets/entitlement-policy-80.png'
import '../../../assets/login.svg'
import '../../../assets/LawFavicon_32x32.png'
import '../../../assets/LawFavicon_64x64.png'
import '../../../assets/LawFavicon_80x80.png'
import '../../../assets/login-16.png'
import '../../../assets/login-32.png'
import '../../../assets/login-80.png'
import '../../../assets/home.svg'
import '../../../assets/home-16.png'
import '../../../assets/home-32.png'
import '../../../assets/home-80.png'
import '../../../assets/logout.svg'
import '../../../assets/logout-16.png'
import '../../../assets/logout-32.png'
import '../../../assets/logout-80.png'
import '../../../assets/compliance-audit.svg'
import '../../../assets/compliance-audit-16.png'
import '../../../assets/compliance-audit-32.png'
import '../../../assets/compliance-audit-80.png'
import '../../../assets/build-queue-new.svg'
import '../../../assets/build-queue-new-16.png'
import '../../../assets/build-queue-new-32.png'
import '../../../assets/build-queue-new-80.png'
import '../../../assets/folder-list.svg'
import '../../../assets/folder-list-16.png'
import '../../../assets/folder-list-32.png'
import '../../../assets/folder-list-80.png'
import '../../../assets/page-edit-16.png'
import '../../../assets/page-edit-32.png'
import '../../../assets/page-edit-80.png'
import '../../../assets/PageAdd-16.png'
import '../../../assets/PageAdd-32.png'
import '../../../assets/PageAdd-80.png'
import '../../../assets/CopyEdit.svg'
import '../../../assets/CopyEdit-16.png'
import '../../../assets/CopyEdit-32.png'
import '../../../assets/CopyEdit-80.png'
import '../../../assets/clause-adviser-icon.svg'
import '../../../assets/clause-adviser-icon-16.png'
import '../../../assets/clause-adviser-icon-32.png'
import '../../../assets/clause-adviser-icon-80.png'

export const MorePagesIconName = 'ChevronDown'
export const LastPageIconName = 'CircleStop'
