import CanCan from 'cancan'
import { matchPath } from 'react-router-dom'

import { AccessControls, ResourceAccessLevel, checkUserAccess } from '@blaw/contracts-api-schema'
import { SessionInfo } from './SessionInfoStorage'

export const Role = {
  Admin: 'admin',
  Lawyer: 'lawyer',
  Business: 'business',
} as const

export const Action = {
  Create: 'Create',
  Edit: 'Edit',
  View: 'View',
  Delete: 'Delete',
} as const

// pages
class Home {}
class Contracts {}
class Clauses {}
class Templates {}
class ClauseAdviser {}
class PracticalGuidance {}
class ContractView {}
class DocumentOutline {}

// resources
class Contract {}
class ContractStatus {}
class ContractDetails {}
class ContractTerms {}
class ContractDocuments {}
class DocumentHistory {}
class DocumentVersion {}
class CompanyClauses {}
class Template {}

// roles
class Admin {}
class Lawyer {}
class Business {}

const RouteMapping = {
  '/': Home,
  '/contracts': Contracts,
  '/clauses': Clauses,
  '/templates': Templates,
  '/clauseAdviser': ClauseAdviser,
  '/guidance': PracticalGuidance,
  '/contracts/:id': ContractView,
  '/outline': DocumentOutline,
  '/contracts/files/new': ContractDocuments,
  '/contracts/versions/new': DocumentVersion,
}

function getRole(role: string | null) {
  switch (role) {
    case Role.Admin:
      return new Admin()
    case Role.Lawyer:
      return new Lawyer()
    case Role.Business:
      return new Business()
    default:
      console.error(Error(`Unsupported user role '${role}'`))
      return new Business()
  }
}

export default class AccessControl {
  cancan: CanCan
  role: Admin | Lawyer | Business
  groups: string[]
  uuid: string
  enableResourceAccessControl: boolean

  constructor(userInfo: SessionInfo, enableResourceAccessControl: boolean) {
    this.cancan = new CanCan()
    this.role = getRole(userInfo.userRole)
    this.groups = JSON.parse(userInfo.userGroups || '[]')
    this.uuid = userInfo.uuid || ''
    this.enableResourceAccessControl = enableResourceAccessControl

    this.cancan.allow(Admin, 'manage', 'all')

    this.cancan.allow(Lawyer, Action.Create, 'all')
    this.cancan.allow(Lawyer, Action.View, 'all')
    this.cancan.allow(Lawyer, Action.Delete, 'all')
    this.cancan.allow(Lawyer, Action.Edit, Contract)
    this.cancan.allow(Lawyer, Action.Edit, ContractStatus)
    this.cancan.allow(Lawyer, Action.Edit, ContractDetails)
    this.cancan.allow(Lawyer, Action.Edit, ContractTerms)
    this.cancan.allow(Lawyer, Action.Edit, ContractDocuments)
    this.cancan.allow(Lawyer, Action.Edit, DocumentVersion)
    this.cancan.allow(Lawyer, Action.Create, Template)
  }

  isAdmin() {
    return this.role instanceof Admin
  }

  canViewRoute(route: string) {
    if (this.cancan.can(this.role, Action.View, 'all')) return true

    const match = Object.keys(RouteMapping).find(r => matchPath(r, route))
    if (match) {
      return this.cancan.can(
        this.role,
        Action.View,
        RouteMapping[match as keyof typeof RouteMapping],
      )
    }
    return false
  }

  canAccessResource(accessControls: Partial<AccessControls>, minAccessLevel: ResourceAccessLevel) {
    if (!this.enableResourceAccessControl) return true
    return (
      this.isAdmin() ||
      (accessControls &&
        checkUserAccess(accessControls as AccessControls, this.groups, this.uuid) >= minAccessLevel)
    )
  }

  canCreateContract() {
    return this.cancan.can(this.role, Action.Create, Contract)
  }

  canEditContractStatus(accessControls: Partial<AccessControls>) {
    return (
      this.cancan.can(this.role, Action.Edit, ContractStatus) &&
      this.canAccessResource(accessControls, ResourceAccessLevel.READ_WRITE)
    )
  }

  canEditContractDetails(accessControls: Partial<AccessControls>) {
    return (
      this.cancan.can(this.role, Action.Edit, ContractDetails) &&
      this.canAccessResource(accessControls, ResourceAccessLevel.READ_WRITE)
    )
  }

  canEditContractTerms(accessControls: Partial<AccessControls>) {
    return (
      this.cancan.can(this.role, Action.Edit, ContractTerms) &&
      this.canAccessResource(accessControls, ResourceAccessLevel.READ_WRITE)
    )
  }

  canEditContractDocuments(accessControls: Partial<AccessControls>) {
    return (
      this.cancan.can(this.role, Action.Edit, ContractDocuments) &&
      this.canAccessResource(accessControls, ResourceAccessLevel.READ_WRITE)
    )
  }

  canViewDocumentHistory(accessControls: Partial<AccessControls>) {
    return (
      this.cancan.can(this.role, Action.View, DocumentHistory) &&
      this.canAccessResource(accessControls, ResourceAccessLevel.READ_ONLY)
    )
  }

  canEditDocumentVersion(accessControls: Partial<AccessControls>) {
    return (
      this.cancan.can(this.role, Action.Edit, DocumentVersion) &&
      this.canAccessResource(accessControls, ResourceAccessLevel.READ_WRITE)
    )
  }

  canDeleteDocumentVersion(accessControls: Partial<AccessControls>) {
    return (
      this.cancan.can(this.role, Action.Delete, DocumentVersion) &&
      this.canAccessResource(accessControls, ResourceAccessLevel.RW_DELETE)
    )
  }

  canEditCompanyClauses() {
    return this.cancan.can(this.role, Action.Edit, CompanyClauses)
  }

  canCreateTemplate() {
    return this.cancan.can(this.role, Action.Create, Template)
  }
}
