import { useContext, useEffect, useState, CSSProperties } from 'react'
import {
  Text,
  Stack,
  TextField,
  Toggle,
  MessageBar,
  MessageBarType,
  Spinner,
  SpinnerSize,
  ProgressIndicator,
} from '@fluentui/react'

import TopNav from '@components/TopNav'
import StyledStack from '@components/StyledStack'
import LoadingShimmer from '@components/LoadingShimmer'
import CollapsibleItem from '@components/CollapsibleItem'
import UnstyledList from '@baseComponents/UnstyledList'
import { truncateText, copyObject, withTimeout } from '@modules/utils'
import { AnalysisToolsContext } from '@contexts/AnalysisToolsContext'
import { fetchDocumentAnalysis } from '@modules/DocumentAnalysisAsync'
import QuickMessage from '@components/QuickMessage'
import { LightTheme } from '@src/themes'
import { selectSectionInDocument } from '@modules/wordDocument'
import { useDebounce } from '@hooks/useDebounce'
import FloatingMessage from '@components/FloatingMessage'
import useFunctionBlocker from '@hooks/useFunctionBlocker'
import { selectedLeftBorderStyle } from '@modules/sharedStyles'
import { useTranslation } from '@hooks/useTranslation'
import { useContractTaskPaneViewed } from '@modules/analytics'

const pageTitle = 'Document Outline'
const OUTLINE_TIMEOUT = 10000
const lastChildStyle: CSSProperties = { borderBottom: '0', paddingBottom: '0' }
const leftSpacing = 0.3
const selectedSectionStyle: CSSProperties = {
  backgroundColor: LightTheme.palette.neutralLight,
  ...selectedLeftBorderStyle,
}

type Section = {
  block_id: string
  paragraph_index: number
  occurence_index: number
  length: number
  offset: number
  text: string
  key: string
  active: boolean
  children: Section[]
}

type SrfAnalysis = {
  outline: Section[]
}

export default function Outline() {
  const { docBodyText, setDocBodyText, getDocBodyText } = useContext(AnalysisToolsContext)
  const { DocumentSelectionChanged } = Office.EventType
  const { addHandlerAsync, removeHandlerAsync } = Office.context.document
  const [loading, setLoading] = useState(true)
  const [reloading, setReloading] = useState(false)
  const [changed, setChanged] = useState(false)
  const [error, setError] = useState('')
  const [outline, setOutline] = useState<Section[]>([])
  const [expanded, setExpanded] = useState(true)
  const [query, setQuery] = useState('')
  const [selected, setSelected] = useState('')
  const { blockableFunction, isBlocked, getCurrFuncName } = useFunctionBlocker()
  const { t } = useTranslation()

  useContractTaskPaneViewed({ pageTitle })

  const filteredOutline = () => {
    return outline
      .map(section => copyObject(section))
      .filter((sectionCopy: Section) => filterOutlineRecursive(sectionCopy))
  }

  const debounceGetOutline = useDebounce(
    async () => await blockableFunction('Outline', getOutlineAgain),
    OUTLINE_TIMEOUT,
    [],
  )

  async function getOutlineAgain() {
    setError('')
    setReloading(true)
    try {
      const {
        analysis: { outline },
      } = await fetchDocumentAnalysis<SrfAnalysis>('srf')
      const formattedOutline = formatOutline(outline)
      setOutline(formattedOutline)
    } catch (e) {
      console.error(e)
      setError('Unable to detect Outline.')
    } finally {
      setReloading(false)
      if (!isBlocked('Outline')) {
        setChanged(false)
      }
    }
  }

  async function getOutline() {
    setError('')
    setLoading(true)
    try {
      const {
        analysis: { outline },
      } = await fetchDocumentAnalysis<SrfAnalysis>('srf')
      const formattedOutline = formatOutline(outline)
      setOutline(formattedOutline)
    } catch (e) {
      console.error(e)
      setError('Unable to detect Outline.')
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    blockableFunction('Outline', getOutline)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  async function checkSelection() {
    try {
      const newText = await withTimeout(15000, 'getDocBodyText', getDocBodyText())

      if (newText !== docBodyText) {
        setChanged(true)
        setDocBodyText(newText)
        if (getCurrFuncName() === 'Outline') {
          await blockableFunction('Outline', getOutlineAgain)
        } else {
          debounceGetOutline()
        }
      }
    } catch (e) {
      console.error(e)
    }
  }

  useEffect(() => {
    addHandlerAsync(DocumentSelectionChanged, checkSelection)
    return () => {
      removeHandlerAsync(DocumentSelectionChanged, { handler: checkSelection })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [docBodyText, loading])

  return (
    <>
      <TopNav title={pageTitle} showAIBadge />
      <StyledStack style={{ overflow: 'hidden' }}>{mainContent()}</StyledStack>
    </>
  )

  function mainContent() {
    if (loading) return renderProgress()

    return (
      <>
        <Stack.Item>
          <Text>View and navigate through the content of your document.</Text>
        </Stack.Item>
        {renderOutlineActions()}
        <Stack.Item>
          <UnstyledList>{renderOutline()}</UnstyledList>
        </Stack.Item>
        <Stack.Item>
          <FloatingMessage show={changed}>
            <MessageBar messageBarType={MessageBarType.info} style={{ width: '100%' }}>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div>{'File changed'}</div>
                <div style={{ display: reloading ? 'flex' : 'none' }}>
                  <div>{'Updating Outline'}</div>
                  <Spinner size={SpinnerSize.small} style={{ marginLeft: '0.5em' }} />
                </div>
              </div>
            </MessageBar>
          </FloatingMessage>
        </Stack.Item>
      </>
    )
  }

  function renderProgress() {
    return (
      <>
        <ProgressIndicator label={t('label.Analyzing Document')} />
        <LoadingShimmer />
      </>
    )
  }

  function filterOutlineRecursive(sectionCopy: Section): boolean {
    const isParentMatch = truncateText(sectionCopy.text).toLowerCase().includes(query.toLowerCase())
    const childrenMatches = sectionCopy.children
      .map((child: Section) => copyObject(child))
      .filter((childCopy: Section) => filterOutlineRecursive(childCopy))

    sectionCopy.children = childrenMatches
    sectionCopy.active = expanded

    return isParentMatch || childrenMatches.length != 0
  }

  function handleSelected(section: Section) {
    selectSectionInDocument(section.paragraph_index)
    setSelected(section.block_id)
  }

  function renderOutlineRecursive(section: Section, depth = 1) {
    return (
      <UnstyledList>
        {section.children.map((child: Section, childIndex, childArray) => {
          const childStyle = childIndex === childArray.length - 1 ? lastChildStyle : {}
          const sectionStyle = child.block_id === selected ? selectedSectionStyle : {}
          const spacingStyle = child.block_id === selected ? depth : depth + leftSpacing
          return (
            <CollapsibleItem
              key={child.block_id + expanded + query}
              item={child}
              itemHeader={child => truncateText(child.text)}
              itemContent={child => renderOutlineRecursive(child, depth + 1)}
              onSelectItem={handleSelected}
              listItemStyles={childStyle}
              wrapperStyles={{ ...sectionStyle, paddingLeft: spacingStyle + 'em' }}
            />
          )
        })}
      </UnstyledList>
    )
  }

  function renderOutlineActions() {
    if (!outline.length || !!error) return

    return (
      <>
        <Stack.Item style={{ marginBottom: '0.5em' }}>
          <TextField
            aria-label="Search Outline"
            placeholder="Search Section Header"
            iconProps={{ iconName: 'Search' }}
            autoComplete="off"
            onKeyUp={e => {
              setQuery(e.currentTarget.value)
              setExpanded(true)
            }}
            autoFocus
          />
        </Stack.Item>
        <Stack.Item>
          <Toggle
            offText="Show All"
            onText="Hide All"
            checked={expanded}
            onChange={() => {
              setExpanded(!expanded)
            }}
          />
        </Stack.Item>
      </>
    )
  }

  function renderOutline() {
    if (error) return <QuickMessage msg={error} type="error" />
    if (!outline.length)
      return (
        <QuickMessage style={{ marginTop: '0.5em' }} msg="Unable to detect Outline." type="info" />
      )

    return filteredOutline().map((section: Section) => {
      const sectionStyle = section.block_id === selected ? selectedSectionStyle : {}
      const marginLeft = `${section.block_id === selected ? 0 : 0.3}em`
      const spacingStyle = { marginLeft, width: '100%' }
      return (
        <CollapsibleItem
          key={section.block_id + expanded + query}
          item={section}
          itemHeader={section => truncateText(section.text)}
          itemContent={section => renderOutlineRecursive(section)}
          onSelectItem={handleSelected}
          wrapperStyles={sectionStyle}
          innerWrapperStyles={spacingStyle}
        />
      )
    })
  }
}

function formatOutline(outline: Section[]): Section[] {
  return outline.map(section => ({
    ...section,
    active: false,
    key: section.block_id,
    children: formatOutline(section.children),
  }))
}
