import { useEffect, useContext, useState } from 'react'
import { ComboBox, IComboBox, IComboBoxOption, IComboBoxStyles } from '@fluentui/react'

import FormDialog from '@components/FormDialog'
import { KeyTermsContext } from '@contexts/KeyTermsContext'
import KeyTermFormFooter from '@components/KeyTermFormFooter'
import useKeyTermForm from '@hooks/useKeyTermForm'
import ErrorMessage from '@components/ErrorMessage'
import { keyTermFormSelectorOptions, getConfig } from '@modules/KeyTerm'
import { ContractContext } from '@contexts/ContractContext'

const comboBoxStyles: Partial<IComboBoxStyles> = {
  optionsContainerWrapper: { maxHeight: '432px' },
  container: { marginBottom: '1em' },
}

const AddKeyTermForm: React.FC = () => {
  const {
    formValid,
    setFormValid,
    selectedKeyTerm,
    setCurrentFormComponent,
    currentFormComponent,
    addKeyTermModalActive,
    submittingKeyTerm,
    metadataConfig,
    loadingMetadataConfig,
  } = useContext(KeyTermsContext)
  const { loading, updateKeyTerms, contract } = useContext(ContractContext)
  const [options, setOptions] = useState<IComboBoxOption[]>()
  const [selectorOptions, setSelectorOptions] = useState<IComboBoxOption[]>()
  const { newKeyTerm, handleFormSubmit, formError } = useKeyTermForm()

  const [FormComponent, keyTermType] = currentFormComponent
  const footerBtnLabel = submittingKeyTerm ? 'Adding...' : 'Add'
  const footerBtnTitle = submittingKeyTerm ? 'Adding Key Term' : 'Add Key Term'

  useEffect(() => {
    if (loadingMetadataConfig || !metadataConfig) return
    setOptions(keyTermFormSelectorOptions(metadataConfig.value))
    setSelectorOptions(options)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingMetadataConfig])

  useEffect(() => {
    if (loadingMetadataConfig || loading || !contract || !metadataConfig || !options) return

    const pointLabels = contract.emptyKeyTerms(metadataConfig.value).map(keyTerm => keyTerm.label)
    const filteredOptions = options.filter(({ text }) => pointLabels.includes(text))
    setSelectorOptions(filteredOptions)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contract, loading, options])

  useEffect(() => {
    if (!selectedKeyTerm?.key || loadingMetadataConfig || !metadataConfig) return
    setCurrentFormComponent(getConfig(selectedKeyTerm.key, metadataConfig.value))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedKeyTerm])

  const selectForm = (_event: React.FormEvent<IComboBox>, option?: IComboBoxOption): void => {
    if (option && metadataConfig) {
      setCurrentFormComponent(getConfig(option.key, metadataConfig.value))
      setFormValid(false)
    }
  }

  if (!(addKeyTermModalActive && contract && metadataConfig && selectorOptions)) return null

  return (
    <FormDialog title="Add Key Term">
      <form
        onSubmit={e =>
          newKeyTerm &&
          handleFormSubmit(
            'add',
            contract.editKeyTerm(newKeyTerm, metadataConfig.value),
            e,
            updateKeyTerms,
          )
        }
      >
        <ErrorMessage message={formError} />
        <ComboBox
          styles={comboBoxStyles}
          label="Term Type"
          required
          options={selectorOptions}
          placeholder="Enter Term Type"
          onChange={selectForm}
          disabled={loadingMetadataConfig || submittingKeyTerm}
          allowFreeInput
        />
        {FormComponent && keyTermType && <FormComponent keyTermType={keyTermType} />}
        <KeyTermFormFooter
          formValid={formValid}
          primaryBtnLabel={footerBtnLabel}
          primaryBtnTitle={footerBtnTitle}
          disabled={loadingMetadataConfig || submittingKeyTerm}
        />
      </form>
    </FormDialog>
  )
}

export default AddKeyTermForm
