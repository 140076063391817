import { useContext, useEffect, useRef, useState } from 'react'
import {
  Checkbox,
  ICheckboxProps,
  List,
  MessageBar,
  MessageBarType,
  PrimaryButton,
  ProgressIndicator,
  SharedColors,
  Stack,
  TextField,
} from '@fluentui/react'
import { useNavigate } from 'react-router-dom'
import { t } from 'i18next'

import { Resource } from '@blaw/contracts-api-schema'
import { ContractContext } from '@contexts/ContractContext'
import { friendlyDate } from '@modules/utils'
import { getDocBodyText } from '@modules/wordDocument'
import { PaginationFooterProps } from '@hooks/usePagination'
import { StoreContext } from '@contexts/StoreContext'
import { TemplatesContext } from '@contexts/TemplatesContext'
import { useContractTaskPaneViewed } from '@modules/analytics'
import { useTranslation } from '@hooks/useTranslation'
import ApiClient from '@modules/ApiClient'
import BoldText from '@baseComponents/BoldText'
import ErrorMessage from '@components/ErrorMessage'
import FacetGroups from '@components/FacetGroups'
import KeywordSearch from '@components/KeywordSearch'
import LinkButton from '@components/LinkButton'
import LoadingShimmer from '@components/LoadingShimmer'
import NotificationBadge from '@components/NotificationBadge'
import ResourceTitle from '@components/ResourceTitle'
import StyledStack from '@components/StyledStack'
import StyledTextField from '@components/StyledTextField'
import Text from '@baseComponents/Text'
import TopNav from '@components/TopNav'
import useSortedFacetGroups from '@hooks/useSortedFacetGroups'

export const playbookTemplatesPerPage = 5
const pageTitle = 'Playbooks'

export default function Playbooks() {
  const [checking, setChecking] = useState(false)
  const [error, setError] = useState('')
  const [message, setMessage] = useState('')
  const [partyRole, setPartyRole] = useState('')
  const [templateId, setSelectedTemplate] = useState<string | null>(null)
  const { t } = useTranslation()
  const navigate = useNavigate()
  const {
    cancelFilters,
    error: templatesError,
    facetLabels,
    filterState,
    handleFiltering,
    items: { facets },
    loading,
    numFilters,
    Pagination,
    query,
    search,
    sortOrder,
    templates,
    warn,
  } = useContext(TemplatesContext)
  const { storeSessionInfo, routes } = useContext(StoreContext)
  const formRef = useRef<HTMLFormElement>(null)
  const { contract, getContractTypeFacet, loading: contractLoading } = useContext(ContractContext)
  const isLoading = loading && contractLoading
  const facetGroup = useSortedFacetGroups(facets, facetLabels, filterState, sortOrder).filter(
    g => g.field === 'contract_type',
  )
  const disableInput = loading || checking
  const loadingFade = { opacity: disableInput ? 0.5 : 1 }
  const stackStyles = { marginTop: '1em', ...loadingFade }

  useContractTaskPaneViewed({ pageTitle })

  useEffect(() => {
    loading && setSelectedTemplate(null)
  }, [loading])

  useEffect(() => {
    query || cancelFilters()
  }, [query])

  useEffect(() => {
    if (contractLoading || !contract) return

    const typeFacet = getContractTypeFacet(facets)
    if (typeFacet) handleFiltering('contract_type', typeFacet.value, true)
  }, [contractLoading])

  return (
    <form onSubmit={submit} ref={formRef}>
      <TopNav title={pageTitle} showAIGeneratedBadge />
      <ErrorMessage message={error || templatesError} />
      <ErrorMessage message={warn} type={MessageBarType.warning} />

      <MessageBar>
        Select a template and provide optional criteria below to check the current document for
        compliance.
      </MessageBar>

      <StyledStack>
        <Stack.Item>
          {loading && facetGroup.length === 0 && <LoadingShimmer size={3} />}
          <FacetGroups
            facetsMinimized={false}
            group={facetGroup}
            facetLabels={facetLabels}
            filterState={filterState}
            loading={isLoading || disableInput}
            styles={{ padding: 0 }}
            onRenderLabel={_ =>
              renderContractTypeFilterLabel(numFilters(), t('label.contract-type'))
            }
            // @ts-ignore TODO: TemplatesContext doesn't match the context prop type, need to fix
            context={TemplatesContext}
          />
        </Stack.Item>

        <Stack.Item style={{ ...stackStyles }}>
          <BoldText style={{ margin: '0 0.3em 0.3em 0', display: 'inline-block', ...loadingFade }}>
            {t('label.template')} <span style={{ color: SharedColors.red20 }}>*</span>
          </BoldText>
          {templateId && <NotificationBadge>1</NotificationBadge>}

          <KeywordSearch
            autoFocus
            query={query}
            loading={disableInput}
            search={search}
            placeholder={t('placeholder.templates-search')}
          />
        </Stack.Item>

        <Stack.Item style={stackStyles}>
          {loading && templates.length === 0 && <LoadingShimmer size={3} />}
          <Pagination Footer={p => <PaginationFooter {...p} templatesLoading={loading} />}>
            <List
              key={templateId}
              items={templates}
              onRenderCell={(item, i) => (
                <TemplateCheckbox
                  index={i}
                  item={item}
                  templateId={templateId}
                  checked={item && templateId === item.id}
                  onChange={(_e, checked) => item && setSelectedTemplate(checked ? item.id : null)}
                />
              )}
              onShouldVirtualize={() => false}
              style={{ ...loadingFade }}
            />
          </Pagination>
        </Stack.Item>

        <Stack.Item style={{ ...stackStyles, marginBottom: '0.5em' }}>
          <StyledTextField
            label={t('label.Party Role')}
            placeholder={t('placeholder.Party Role')}
            value={partyRole}
            disabled={disableInput}
            onChange={(_e, value) => setPartyRole(value ?? '')}
          />
        </Stack.Item>

        <Stack.Item style={stackStyles}>
          <BoldText style={{ marginBottom: '0.5em', display: 'inline-block', ...loadingFade }}>
            {t('label.Additional Criteria')}
          </BoldText>
          <TextField
            multiline
            autoAdjustHeight
            value={message}
            disabled={disableInput}
            placeholder={t('placeholder.Additional Criteria')}
            onChange={(_, value) => setMessage(value ?? '')}
          />
          <PrimaryButton
            disabled={!templateId || isLoading || checking}
            onClick={submit}
            style={{ marginTop: '0.7em', width: '100%' }}
          >
            {t('button.Check for Compliance')}
          </PrimaryButton>
          {checking && <ProgressIndicator />}
        </Stack.Item>
      </StyledStack>
      <input type="submit" style={{ display: 'none' }} />
    </form>
  )

  async function submit(e: { preventDefault: () => void }) {
    e.preventDefault()
    if (!formRef.current?.checkValidity() || !templateId) return

    setChecking(true)
    setError('')
    const apiClient = new ApiClient(storeSessionInfo, setError)

    try {
      const contract = await getDocBodyText()
      const payload = { templateId, contract, partyRole, message }
      const { data } = await apiClient.post(routes.playbooksComplianceUrl, payload)
      navigate('/playbooks/compliance/summary', { state: data })
    } catch (e) {
      setError((e as Error).message)
    } finally {
      setChecking(false)
    }
  }
}

type TemplateCheckboxProps = ICheckboxProps & {
  templateId: string | null
  item?: Resource
  index?: number
}

function TemplateCheckbox({ index, item, checked, onChange, templateId }: TemplateCheckboxProps) {
  return (
    <Stack
      horizontal
      style={{
        width: '100%',
        alignItems: 'center',
        marginBottom: '0.5em',
        justifyContent: 'space-between',
      }}
    >
      <Checkbox
        required={index === 0 && !templateId}
        checked={checked}
        onChange={onChange}
        onRenderLabel={() => (item && <ResourceTitle item={item} />) || null}
      />
      {item && (
        <Text variant="small">{item.updated ? friendlyDate(item.updated) : t('label.N/A')}</Text>
      )}
    </Stack>
  )
}

function PaginationFooter({
  loading,
  loadMore,
  onLastPage,
  numHitsRangeLabel,
  templatesLoading,
}: PaginationFooterProps & { templatesLoading: boolean }) {
  const isLoading = loading || templatesLoading
  return (
    <Stack horizontal style={{ justifyContent: 'space-between', opacity: isLoading ? 0.5 : 1 }}>
      <LinkButton disabled={isLoading || onLastPage} onClick={loadMore}>
        {isLoading ? 'Loading...' : 'Show more'}
      </LinkButton>
      <Text>{numHitsRangeLabel}</Text>
    </Stack>
  )
}

function renderContractTypeFilterLabel(numFilters: number, label: string) {
  return (
    <Stack horizontal style={{ gap: '0.5em' }}>
      <BoldText>{label}</BoldText>
      {numFilters > 0 && <NotificationBadge>{numFilters}</NotificationBadge>}
    </Stack>
  )
}
